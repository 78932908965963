<template>
  <div
    class="toolbar-right__wrap"
    :class="{'toolbar-right-block__wrap--expand': isShrunk}"
  >
    <div
      class="toolbar-righ__double-arrow display-none m-flex relative align-self-end"
      @click="handleShrunk"
    >
      <gl-icon
        :height="24"
        light
        :name="!isShrunk ? 'double-arrow-up' : 'double-arrow-down'"
        :width="24"
      />
      <div
        v-if="!isShrunk && Object.keys(selectedElement).length !== 0"
        class="has-data__mark"
      />
    </div>
    <div
      class="toolbar toolbar__controls toolbar__controls--right column m-row"
      :class="{'toolbar__controls--collapse': !isShrunk}"
    >
      <gl-menu-item
        v-tooltip.left="{
          content: 'Add tx hash / wallet address to graph',
          visible: isDesktop,
        }"
        class="mb-3 m-mb-0"
        icon="add"
        :icon-height="24"
        :icon-width="24"
        @click="add"
      />
      <gl-menu-item
        v-tooltip.left="{
          content: 'Find route in graph',
          visible: isDesktop,
        }"
        class="mb-3 m-mb-0"
        icon="find-route"
        :icon-height="24"
        :icon-width="24"
        @click="findRoute"
      />
      <gl-menu-item
        v-tooltip.left="{
          content: 'Undo last request',
          visible: isDesktop,
        }"
        class="mb-3 m-mb-0"
        icon="undo"
        :icon-height="24"
        :icon-width="24"
        @click="undoLast"
      />
      <gl-menu-item
        v-tooltip.left="{
          content: 'Redo last request',
          visible: isDesktop,
        }"
        class="mb-3 m-mb-0"
        icon="redo"
        :icon-height="24"
        :icon-width="24"
        @click="redoLast"
      />
      <gl-menu-item
        v-if="!isMobile"
        v-tooltip.left="{
          content: 'Zoom in',
          visible: isDesktop,
        }"
        class="mb-3 m-mb-0"
        @click="changeZoom('in')"
      >
        <template #icon>
          <gl-icon
            :height="24"
            light
            name="zoom-in"
            :width="24"
          />
        </template>
      </gl-menu-item>
      <gl-menu-item
        v-if="!isMobile"
        v-tooltip.left="{
          content: 'Zoom out',
          visible: isDesktop,
        }"
        class="mb-3 m-mb-0"
        @click="changeZoom('out')"
      >
        <template #icon>
          <gl-icon
            :height="24"
            light
            name="zoom-out"
            :width="24"
          />
        </template>
      </gl-menu-item>
      <gl-menu-item
        v-tooltip.left="{
          content: 'Increase nodes spacing',
          visible: isDesktop,
        }"
        class="mb-3 m-mb-0"
        @click="changeNodeSpacing(45)"
      >
        <template #icon>
          <gl-icon
            :height="24"
            name="external-link"
            :width="24"
          />
        </template>
      </gl-menu-item>
      <gl-menu-item
        v-tooltip.left="{
          content: 'Decrease node spacing',
          visible: isDesktop,
        }"
        class="mb-3 m-mb-0"
        @click="changeNodeSpacing(10)"
      >
        <template #icon>
          <gl-icon
            :height="24"
            name="external-link-revert"
            :width="24"
          />
        </template>
      </gl-menu-item>
      <gl-menu-item
        v-tooltip.left="{
          content: 'Lock selected nodes',
          visible: isDesktop,
        }"
        class="mb-3 m-mb-0"
        icon="lock"
        :icon-height="24"
        :icon-width="24"
        @click="lockNodes"
      />
      <gl-menu-item
        v-tooltip.left="{
          content: 'Unlock selected nodes',
          visible: isDesktop,
        }"
        class="mb-3 m-mb-0"
        icon="unlock"
        :icon-height="24"
        :icon-width="24"
        @click="unlockNodes"
      />
      <gl-menu-item
        v-tooltip.left="{
          content: 'Hide nodes',
          visible: isDesktop,
        }"
        class="mb-3 m-mb-0"
        icon="hide"
        :icon-height="24"
        :icon-width="24"
        @click="hideNodes"
      />
      <gl-menu-item
        v-tooltip.left="{
          content: 'Show hidden nodes',
          visible: isDesktop,
        }"
        class="mb-3 m-mb-0"
        icon="unhide"
        :icon-height="24"
        :icon-width="24"
        @click="showHidden"
      />
    </div>
    <div
      class="right flex column"
      :class="{'toolbar-right__wrap--expand': isShrunk}"
    >
      <div class="toolbar-right__header pointer mb-4">
        <div
          v-show="isShrunk"
          class="toolbar-right__header-text"
        >
          {{ title }}
        </div>
        <div
          class="toolbar-right__header-collapse-btn relative"
          @click="handleShrunk"
        >
          <gl-icon
            :height="24"
            light
            :name="!isShrunk ? 'expand' : 'collapse'"
            :width="24"
          />
          <div
            v-if="!isShrunk && Object.keys(selectedElement).length !== 0"
            class="has-data__mark"
          />
        </div>
      </div>
      <div v-show="isShrunk" class="flex-grow-1 all-types-info">
        <div
          v-if="loading"
          class="flex justify-center"
        >
          <gl-loader />
        </div>
        <div
          v-else-if="Object.keys(selectedElement).length === 0"
          class="gl-analytics-dropdown__list-item"
        >
          <div class="empty-analytics">
            <img
              alt="node-preview"
              class="empty-analytics__img"
              src="../../../../public/assets/img/node-preview.png"
            >
            <div class="empty-analytics__count">
              1
            </div>
            <div class="empty-analytics__label">
              Select a node to see the <br> address information
            </div>
          </div>
          <div class="empty-analytics">
            <img
              alt="node-preview"
              class="empty-analytics__img"
              src="../../../../public/assets/img/tx-preview.png"
            >
            <div class="empty-analytics__count">
              2
            </div>
            <div class="empty-analytics__label">
              Select a transaction to see the <br> transaction information
            </div>
          </div>
          <div class="empty-analytics">
            <img
              alt="node-preview"
              class="empty-analytics__img"
              src="../../../../public/assets/img/cluster-preview.png"
            >
            <div class="empty-analytics__count">
              3
            </div>
            <div class="empty-analytics__label">
              Select a cluster to see the <br> cluster information
            </div>
          </div>
        </div>
        <EthAddressInfo
          v-else-if="(selectedElement.data.type === 'eth' || selectedElement.data.type === 'bnb' || selectedElement.data.type === 'trx') && selectedElement.data.nodeData"
          :cytoscape="cytoscape"
          data-cy="transactionAnalytics"
          :search-mode="searchMode"
          :selected-element="selectedElement"
          @set-eth-transaction="provideEthTx"
        />
        <EthTransactionInfo
          v-else-if="selectedElement.type === 'eth' || selectedElement.type === 'bnb' || selectedElement.type === 'trx'"
          :cytoscape="cytoscape"
          data-cy="transactionAnalytics"
          :search-mode="searchMode"
          :selected-element="selectedElement"
        />
        <AddressInfo
          v-else-if="selectedElement.type === 'address'"
          :cytoscape="cytoscape"
          data-cy="addressAnalytics"
          :selected-element="selectedElement"
          @add-tx-data="provideTx"
          @search-and-hide-elements="provideHide"
          @set-transaction="provide"
          @toggle-calendar="calendarOpen = !calendarOpen"
        />
        <ClusterInfo
          v-else-if="selectedElement.type === 'cluster'"
          data-cy="clusterAnalytics"
          :selected-element="selectedElement"
          @add-tx-data="provideTx"
        />
        <TransactionInfo
          v-else
          :cytoscape="cytoscape"
          data-cy="transactionAnalytics"
          :search-mode="searchMode"
          :selected-element="selectedElement"
          @add-tx-data="provideTx"
        />
      </div>
    </div>
  </div>
</template>

<script>
//Components
import GlMenuItem from '@/components/gl-menu-item'
import GlIcon from '@/components/gl-icon'
import AddressInfo from '../analytics-info-blocks/address-info'
import ClusterInfo from '../analytics-info-blocks/cluster-info'
import TransactionInfo from '../analytics-info-blocks/transaction-info'
import EthTransactionInfo from "@/pages/analytics/analytics-info-blocks/eth-transaction-info";
import EthAddressInfo from "@/pages/analytics/analytics-info-blocks/eth-address-info";
import GlLoader from '@/components/gl-loader'
// Vuex
import { mapMutations } from "vuex";
// Mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'

export default {
  components: {
    GlMenuItem,
    GlIcon,
    AddressInfo,
    TransactionInfo,
    EthTransactionInfo,
    EthAddressInfo,
    ClusterInfo,
    GlLoader,
  },
  mixins: [deviceWidthMixin],
  props: {
    searchMode: {
      type: String,
      default: 'tx',
    },
    loading: {
      type: Boolean,
      default: true,
    },
    selectedElement: {
      type: Object,
      default: () => ({})
    },
    cytoscape: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      isShrunk: false
    }
  },
  computed: {
    title() {
      if (Object.keys(this.selectedElement).length === 0) return ''
      if (this.selectedElement?.data?.nodeData?.isContract && this.selectedElement?.data?.nodeData?.hasSymbol) return 'Token Contract Info'
      if (this.selectedElement?.data?.nodeData?.isContract && !this.selectedElement?.data?.nodeData?.hasSymbol) return 'Contract Info'
      if (this.selectedElement.type === 'address' || (this.selectedElement.data && this.selectedElement.data.nodeData)) return 'Address Info'
      if (this.selectedElement.type === 'cluster') {
        return 'Cluster Info'
      } else {
        return 'Transaction Info'
      }
    }
  },
  watch: {
    selectedElement: {
      deep: true,
      handler(prev) {
        this.isShrunk = !!prev.value
        if (this.isShrunk) {
          this.SET_SHRUNK(true)
        }

        const alwaysOpen = JSON.parse((localStorage.getItem('alwaysOpen')))

        if (alwaysOpen !== undefined) {
          this.isShrunk = alwaysOpen
        }
      },
    },
  },
  created() {
    if (this.isMobile) {
      this.$root.$on('toggle-toolbar-right-shrunk', (val) => {
        this.isShrunk = val
      })
    }
  },
  beforeDestroy() {
    if (this.isMobile) {
      this.$root.$off("toggle-toolbar-right-shrunk");
    }
  },
  methods: {
    ...mapMutations({
      SET_STEPPED_STATE: 'analytics/SET_STEPPED_STATE',
      SET_SHRUNK: 'sidebar/SET_SHRUNK'
    }),
    crossing() {
      this.$emit('crossing')
    },
    changeNodeSpacing(val) {
      this.$emit('change-node-spacing', val)
    },
    handleShrunk() {
      this.isShrunk = !this.isShrunk
      localStorage.setItem('alwaysOpen', this.isShrunk)
    },
    provide(val) {
      this.$emit('set-transaction', val)
    },
    provideEthTx(data) {
      this.$emit('add-eth-tx-data', data)
    },
    provideHide(val) {
      this.$emit('search-and-hide-elements', val)
    },
    provideTx(val) {
      this.$emit('add-tx-data', val)
    },
    changeZoom(val) {
      this.$emit('change-zoom', val)
    },
    add() {
      this.$emit('add')
    },
    findRoute() {
      this.$emit('find-route')
    },
    undoLast() {
      this.SET_STEPPED_STATE({ undo: false, redo: true })
      this.$emit('undo')
    },
    redoLast() {
      this.SET_STEPPED_STATE({ undo: true, redo: false })
      this.$emit('redo')
    },
    lockNodes() {
      this.$emit('lock-nodes', ':selected')
    },
    unlockNodes() {
      this.$emit('unlock-nodes', ':selected')
    },
    hideNodes() {
      this.$emit('hide-nodes', ':selected')
    },
    showHidden() {
      this.$emit('show-hidden')
    },
  }
}
</script>

<style>
.toolbar-right__wrap {
  display: flex;
  height: 100%;
  align-items: center;
  z-index: 10;
}

.toolbar__controls--right {
  height: fit-content;
  flex-wrap: nowrap;
  overflow-y: auto;
  max-height: calc(100svh - 100px);
  box-shadow: -3px 0 7px 0 rgba(110, 110, 110, 0.11);
  padding: 8px;
  border-right: 1px solid var(--dark-grey-d-3);
  margin-top: 88px;
}
.toolbar__controls--right svg {
  transform: scale(1.1);
}
.toolbar-right-block__wrap--expand .toolbar__controls--right {
  width: 40px;
  padding: 16px 0 0 2px;
}

.toolbar-right__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  border-bottom: 1px solid var(--cotton-grey-f-5);
  font-weight: 600;
  font-size: 14px;
}
.toolbar-right__header-text {
  padding: 16px 0 16px 24px;
}

.toolbar-right__header-collapse-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 51px;
  width: 56px;
}
.toolbar-right-block__wrap--expand .toolbar-right__header-collapse-btn {
  margin-right: -2px;
}

.right {
  background: #fff;
  height: 100%;
  width: 52px;
  overflow: auto;
}
.right::-webkit-scrollbar {
  width: 8px;
}
.right .toolbar-right__header svg {
  height: 25px;
  width: 25px;
  margin-right: -4px;
}

.toolbar-right__wrap--expand {
  width: 640px;
}
.toolbar-right__wrap--expand > div {
  width: 630px;
}

.toolbar__controls--collapse {
  transform: translate(calc(100% + 2px), 0);
  box-shadow: none;
  border-right: none;
}

.has-data__mark {
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: var(--reflex-bluet);
  position: absolute;
  top: 15px;
  right: 15px;
}

.empty-analytics {
  display: flex;
  /*justify-content: space-around;*/
  align-items: center;
  padding: 0;
  padding-left: 78px;
  color: #6e6e6e;
  font-size: 16px;
}
.empty-analytics:first-child {
  margin-top: 91px;
}
.empty-analytics + .empty-analytics {
  margin-top: 150px;
}

.empty-analytics__img {
  width: 150px;
  margin-right: 48px;
}

.empty-analytics__count {
  display: flex;
  align-items: center;
  margin-right: 16px;
  justify-content: center;
  border: 1px solid #979797;
  width: 28px;
  min-width: 28px;
  height: 28px;
  border-radius: 100%;
}

.empty-analytics__label {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.1px;
}

.empty-analytics img {
  width: 150px;
}

.toolbar-right-block__wrap--expand .padder {
  padding-left: 24px;
}
.toolbar-right-block__wrap--expand .stat {
  padding-left: 24px;
}
.toolbar-right-block__wrap--expand .key {
  line-height: 15px;
}

.toolbar-right__wrap--expand .stat-tag .link-text {
  display: block;
}
.toolbar-right__wrap--expand .analytics-tabs .analytics-tabs-item {
  width: 160px;
  padding: 10px 0 12px 0;
}
.toolbar-right__wrap--expand .analytics-tabs .analytics-tabs-item--active::after {
  height: 3px;
}
.toolbar-right__wrap--expand .analytics-tabs--trans .analytics-tabs-item {
  width: 212px;
  min-width: 212px;
  max-width: 212px;
  padding: 10px 0 7px 0;
}
.toolbar-right__wrap--expand .analytics-tabs--trans .analytics-tabs-item--active::after {
  height: 3px;
}
.toolbar-right__wrap--expand .analytics-tabs--trans .analytics-tabs-item {
  padding-left: 8px;
}


.all-types-info .all-types__info-block {
  margin-bottom: 16px;
  //white-space: nowrap;
}
.all-types-info .all-types__info-block .mr-2 {
  margin-right: 0 !important;
}
.all-types-info .all-types__info-block:nth-child(4n - 3) {
  width: 143px;
}
.all-types-info .all-types__info-block:nth-child(4n - 2) {
  width: 139px;
}
.all-types-info .all-types__info-block:nth-child(4n - 1) {
  width: 172px;
}
.all-types-info .all-types__info-block:nth-child(4n) {
  width: 144px;
}
.all-types-info .all-types__info-block-header {
  padding-top: 8px;
}
.all-types-info .o-table__td {
  padding: 2px 3px !important;
  border-bottom: none;
}
.all-types-info .squander,
.all-types-info .profit {
  display: inline-flex !important;
  padding: 2px 8px !important;
  border-radius: 4px;
  font-size: 12px;
  line-height: 18px;
  width: 100%;
}


.all-types-info .pp-trans-all-blocks .all-types__info-block {
  margin-bottom: 16px;
  white-space: nowrap;
}
.all-types-info .pp-trans-all-blocks .all-types__info-block .mr-2 {
  margin-right: 0 !important;
}
.all-types-info .pp-trans-all-blocks .all-types__info-block:nth-child(4n - 3) {
  width: 227px;
}
.all-types-info .pp-trans-all-blocks .all-types__info-block:nth-child(4n - 2) {
  width: 235px;
}
.all-types-info .pp-trans-all-blocks .all-types__info-block:nth-child(4n - 1) {
  width: 100px;
}
.all-types-info .pp-trans-all-blocks .all-types__info-block-header {
  padding-top: 8px;
}
.pp-trans-all-blocks {
  margin-bottom: 7px;
}


.pp-address {
  margin-top: -2px;
  letter-spacing: 0.15px;
}
.pp-address-trans {
  letter-spacing: 0.15px;;
}
.pp-address .sidebar__history-copy,
.pp-address .sidebar__to-labeling {
  transform: scale(0.9);
}
.pp-address .sidebar__to-labeling {
  margin-left: -4px;
}
.pp-transactions-list {
  margin-top: -1px;
}
.pp-profile-flag {
  padding-top: 4px;
  margin-bottom: 7px;
}
.pp-transactions-list-filter > div:first-child {
  width: 331px;
  max-width: 331px;
  margin-right: 24px !important;
}
.pp-tags-empty-block {
  min-height: 25px;
}
.pp-exposure-header {
  padding-left: 24px;
  padding-top: 9px;
  letter-spacing: -0.2px;
}
.pp-trans-tx-hash {
  margin-bottom: 15px;
  padding-left: 24px;
}


/* pp checkbox */
.address-info-table {
  margin-top: -1px;
}
.address-info-table .o-chk {
  margin-right: 0 !important;
}
.address-info-table .o-chk__label {
  display: none;
}
.address-info-table .o-chk__check {
  width: 14px;
  height: 14px;
  border-width: 1px;
  border-radius: 2px;
  border-color: #D3D3D3 !important;
  transition: border-color 0.3s ease;
}
.address-info-table .o-chk__check::after {
  opacity: 0;
  content: "";
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  background-color: #5A78EA;
  border-radius: 2px;
  position: absolute;
  left: 3px;
  top: 3px;
  transition: opacity 0.3s ease;
}
.address-info-table .o-chk__check--checked {
  border-color: #5A78EA !important;
  background-color: transparent !important;
}
.address-info-table .o-chk__check--checked::after {
  opacity: 1;
}

/* address-info-table */
.address-info-table {
  padding-top: 20px;
}
.address-info-table thead tr {
  display: flex;
  margin-bottom: 1px;
}
.address-info-table tbody tr {
  display: flex;
  align-items: center;
  border-top: 1px solid #F5F5F5;
}
.address-info-table .o-table__td {
  padding-left: 0 !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  width: 100%;
}
.address-info-table .o-table__th {
  height: 100%;
  padding: 0 11px 9px 0 !important;
  border-bottom: none;
}
.address-info-table .o-table__th:nth-child(1),
.address-info-table .o-table__td:nth-child(1) {
  width: 35px !important;
  max-width: 35px !important;
  min-width: 35px !important;
  padding-right: 0 !important;
  padding-left: 2px !important;
}
.address-info-table .o-table__th:nth-child(2),
.address-info-table .o-table__td:nth-child(2) {
  width: 125px;
  max-width: 125px;
  min-width: 125px;
}
.address-info-table .o-table__td:nth-child(2) {
  letter-spacing: -0.5px;
  padding-bottom: 5px !important;
}
.address-info-table .o-table__th:nth-child(3),
.address-info-table .o-table__td:nth-child(3) {
  width: 214px;
  max-width: 214px;
  min-width: 214px;
}
.address-info-table .o-table__td:nth-child(3) {
  letter-spacing: -0.4px;
}
.address-info-table .o-table__th:nth-child(4),
.address-info-table .o-table__td:nth-child(4) {
  width: 50px;
  max-width: 50px;
  min-width: 50px;
}

/* new-table-for-tx */
.new-table-for-tx .o-table__th:nth-child(1),
.new-table-for-tx .o-table__td:nth-child(1) {
  width: 51px !important;
  max-width: 51px !important;
  min-width: 51px !important;
  padding-left: 16px !important;
}
.new-table-for-tx .o-table__th:nth-child(2),
.new-table-for-tx .o-table__td:nth-child(2),
.new-table-for-tx .o-table__th:nth-child(3),
.new-table-for-tx .o-table__td:nth-child(3),
.new-table-for-tx .o-table__th:nth-child(4),
.new-table-for-tx .o-table__td:nth-child(4) {
  width: calc(33% - 20px);
  max-width: calc(33% - 20px);
  min-width: calc(33% - 20px);
}
.new-table-for-tx .o-table__th:nth-child(4) .flex,
.new-table-for-tx .o-table__td:nth-child(4) .flex {
  display: block;
}
.new-table-for-tx .o-table__th:nth-child(4) .ml-2,
.new-table-for-tx .o-table__td:nth-child(4) .ml-2 {
  margin-left: 0 !important;
  margin-top: 2px;
  color: var(--dark-grey-6-e);
}
.new-class-for-stat {
  padding-left: 16px !important;
}

/* pp checkbox */
.transaction-info-table {
  margin-top: -1px;
}
.transaction-info-table .o-chk {
  margin-right: 0 !important;
}
.transaction-info-table .o-chk__label {
  display: none;
}
.transaction-info-table .o-chk__check {
  width: 14px;
  height: 14px;
  border-width: 1px;
  border-radius: 2px;
  border-color: #D3D3D3 !important;
  transition: border-color 0.3s ease;
}
.transaction-info-table .o-chk__check::after {
  opacity: 0;
  content: "";
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  background-color: #5A78EA;
  border-radius: 2px;
  position: absolute;
  left: 3px;
  top: 3px;
  transition: opacity 0.3s ease;
}
.transaction-info-table .o-chk__check--checked {
  border-color: #5A78EA !important;
  background-color: transparent !important;
}
.transaction-info-table .o-chk__check--checked::after {
  opacity: 1;
}

/* transaction-info-table */
.transaction-info-table {
  padding-top: 11px;
}
.transaction-info-table thead tr {
  display: flex;
  margin-bottom: 3px;
}
.transaction-info-table tbody tr {
  display: flex;
  align-items: center;
  border-top: 1px solid #F5F5F5;
}
.transaction-info-table .o-table__td {
  padding-left: 0 !important;
  padding-top: 8px !important;
  padding-bottom: 4px !important;
}
.transaction-info-table .o-table__th {
  height: 100%;
  padding: 0 11px 9px 0 !important;
  border-bottom: none;
}
.transaction-info-table .o-table__th:nth-child(1),
.transaction-info-table .o-table__td:nth-child(1) {
  width: 36px !important;
  max-width: 36px !important;
  min-width: 36px !important;
  padding-right: 0 !important;
  padding-left: 2px !important;
}
.transaction-info-table .o-table__th:nth-child(2),
.transaction-info-table .o-table__td:nth-child(2) {
  width: 197px;
  max-width: 197px;
  min-width: 197px;
}
.transaction-info-table .o-table__td:nth-child(2) {
  letter-spacing: -0.5px;
  padding-bottom: 5px !important;
}
.transaction-info-table .o-table__th:nth-child(3),
.transaction-info-table .o-table__td:nth-child(3) {
  width: 113px;
  max-width: 113px;
  min-width: 113px;
}
.transaction-info-table .o-table__td:nth-child(3) > div {
  margin-left: -6px;
}
.transaction-info-table .o-table__th:nth-child(4),
.transaction-info-table .o-table__td:nth-child(4) {
  width: 250px;
  max-width: 250px;
  min-width: 250px;
}
.transaction-info-table .o-table__td:nth-child(4) {
  padding-top: 4px !important;
}


/* cluster-address-table */
.cluster-address-table__header {
  padding-left: 24px !important;
  padding-top: 6px !important;
}
.cluster-address-table {
  padding-top: 18px;
  padding-left: 6px;
  font-size: 12px;
  line-height: 14px;
}
.cluster-address-table thead tr {
  display: flex;
  margin-bottom: 1px;
}
.cluster-address-table tbody tr {
  display: flex;
  align-items: center;
  border-top: 1px solid #F5F5F5;
}
.cluster-address-table .o-table__td {
  padding-left: 0 !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.cluster-address-table .o-table__th {
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 0 0 0 !important;
  border-bottom: none;
}
.cluster-address-table .o-table__th:nth-child(1),
.cluster-address-table .o-table__td:nth-child(1) {
  width: 140px !important;
  max-width: 140px !important;
  min-width: 140px !important;
  padding-right: 0 !important;
  padding-left: 2px !important;
}
.cluster-address-table .o-table__th:nth-child(2),
.cluster-address-table .o-table__td:nth-child(2) {
  width: 96px;
  max-width: 96px;
  min-width: 96px;
}
.cluster-address-table .o-table__td:nth-child(2) {
  letter-spacing: -0.5px;
  padding-bottom: 5px !important;
}
.cluster-address-table .o-table__th:nth-child(3),
.cluster-address-table .o-table__td:nth-child(3) {
  width: 70px;
  max-width: 70px;
  min-width: 70px;
}
.cluster-address-table .o-table__td:nth-child(3) {
  letter-spacing: -0.4px;
}
.cluster-address-table .o-table__th:nth-child(4),
.cluster-address-table .o-table__td:nth-child(4) {
  width: 162px;
  max-width: 162px;
  min-width: 162px;
}
.cluster-address-table .o-table__td:nth-child(4) .align-end {
  align-items: start;
}
.cluster-address-table .o-table__td:nth-child(4) .ml-2 {
  margin-left: 0 !important;
}
.cluster-address-table .o-table__th:nth-child(5),
.cluster-address-table .o-table__td:nth-child(5) {
  width: 120px;
  max-width: 120px;
  min-width: 120px;
}


.address-tags-table {
  padding-left: 8px;
  padding-right: 8px;
}
.address-tags-table .o-table__th {
  padding-left: 0 !important;
}

@media (max-width: 767px) {
  .right {
    height: 0;
  }
  .toolbar-right__wrap {
    flex-direction: column;
    width: 100%;
    height: fit-content;
  }
  .toolbar-right-block__wrap--expand {
    height: 75%
  }
  .toolbar-right__wrap--expand {
    width: 100%;
    height: 100%;
  }
  .toolbar__controls--collapse {
    margin-right: 0;
  }
  .toolbar__controls--right {
    width: 100%;
    max-height: none;
    margin-top: 0;
    overflow-y: unset;
    flex-wrap: wrap;
    transform: translate(0);
  }
  .toolbar-right__header-collapse-btn {
    display: none;
  }
  .toolbar-righ__double-arrow {
    background: var(--white);
    padding: 4px 6px 0px 6px;
    margin: 8px;
  }
  .has-data__mark {
    position: absolute;
    top: 20px;
    left: 0;
  }
  .empty-analytics {
    padding: 16px;
    font-size: 12px;
  }
  .empty-analytics img {
    width: 30%;
    margin-right: 16px;
  }

  .toolbar-right-block__wrap--expand .toolbar__controls--right {
    width: 100%;
  }
  .empty-analytics:first-child {
    margin-top: 0;
  }
  .empty-analytics + .empty-analytics {
    margin-top: 40px;
  }
}
</style>
