<template>
  <main class="gl-analytics">
    <section class="gl-analytics__workspace">
      <div class="full-height fullwidth absolute">
        <div class="flex fullwidth space-between full-height">
          <div class="flex column space-between fullwidth">
            <toolbar-top
              :case-data="caseData"
              class="gl-analytics__toolbar-top"
              :cytoscape="cytoscape"
              :search-mode="searchMode"
              :selected-element="selectedElement"
              :show-cyto="showCyto"
              @change-edge-labels="changeEdgeLabels"
              @change-node-labels="changeNodeLabels"
              @change-view-mode="changeViewMode"
              @export="exportGraph"
              @import="importGraph"
              @save="updateExplorerCase"
              @save-as="exportGraph"
              @toggle-full-view="toggleFullView"
            />
            <toolbar-bottom
              class="gl-analytics__toolbar-bottom m-display-none-important"
              @search="searchElementsOnGraph"
              @set-semi-automatic="setSemiAutomaticMode"
            />
          </div>
          <toolbar-right
            class="gl-analytics__toolbar-right"
            :cytoscape="cytoscape"
            :loading="isLoading"
            :search-mode="searchMode"
            :selected-element="selectedElement"
            @add="showAddModal = true"
            @add-eth-tx-data="virtualizeEthTransactionsList"
            @add-tx-data="addToGraph"
            @change-node-spacing="changeNodeSpacing"
            @change-zoom="changeZoom"
            @crossing="crossing"
            @find-route="showFindRouteModal = true"
            @hide-nodes="hideNodes"
            @lock-nodes="lockNodes"
            @redo="redoLast"
            @search-and-hide-elements="hideFromGraph"
            @set-transaction="virtualizeTransactionsList"
            @show-hidden="showHidden"
            @undo="undoLast"
            @unlock-nodes="unlockNodes"
          />
        </div>
      </div>
      <cytoscape-container
        v-show="showCyto && !isUnknowMode"
        class="gl-analytics__graph"
        @cxttapend="handleContextClick"
        @doubleTap="handleDoubleClick"
        @ready="initCytoscapeService"
        @vclick="handleClick"
      >
        <context-menu
          v-model="showContextMenu"
          :position="contextMenuPosition"
          :selected-node="selectedNode"
          @customize-node="showCustomizeModal = true"
          @deselect-node="deselectNode"
          @hide-nodes="hideNodes"
          @select-incomers="selectIncomers"
          @select-outgoers="selectOutgoers"
        />
        <context-menu-edge
          v-model="showContextMenuEdge"
          :position="contextMenuPosition"
          :selected-node="selectedEdge"
        />
        <context-menu-tacing
          v-model="showContextMenuTracing"
          :cytoscape="cytoscape"
          :position="contextMenuPosition"
          :tracing-data="tracingData"
          @trace="handleTracing"
        />
        <modal-customize
          v-model="showCustomizeModal"
          :selected-node="selectedNode"
          @close="showCustomizeModal = false"
          @customize-node="customizeNode"
        />
        <modal-add
          v-model="showAddModal"
          @add="addToGraph"
          @close="showAddModal = false"
        />
        <modal-find-route
          v-model="showFindRouteModal"
          @close="showFindRouteModal = false"
          @find-route="findRoute"
        />
        <modal-find-intersection
          v-model="showFindIntersectionModal"
          :tx="selectedElement"
          @close="showFindIntersectionModal = false"
          @find-intersection="findIntersection"
        />
        <modal-check-sources v-model="showCheckSourcesModal" />
        <modal-create-case
          v-if="showCreateCaseModal"
          ref="createCaseModal"
          v-model="showCreateCaseModal"
          :search="searchValue || ''"
          @close="showCreateCaseModal = false"
          @create="saveCase"
        />
        <div
          v-if="isLoading"
          class="gl-analytics__loader"
        >
          <gl-loader />
        </div>
      </cytoscape-container>
      <div
        v-show="isUnknowMode"
        class="cyto-empty"
      >
        <gl-icon
          class="mb-1"
          :height="24"
          name="find-dis"
          :width="24"
        />
        <div class="text-center">
          Type address or transaction hash in the <br> search bar above to start exploring.
        </div>
      </div>
      <modal-invalid-json
        v-model="showInvalidJsonModal"
        @close="showInvalidJsonModal = false"
        @submit="showInvalidJsonModal = false"
      />
      <modal-leave-from-analytics
        v-if="leaveFromPage"
        v-model="leaveFromPage"
        @close="leaveFromPage = false"
        @submit="agreeLeave"
      />
    </section>
    <ToolbarActionsWrapper
      v-if="showMobileToolbarBottom && isMobile"
      title="Search on graph"
      @close="showMobileToolbarBottom = false"
    >
      <toolbar-bottom
        class="gl-analytics__toolbar-bottom"
        @search="searchElementsOnGraph"
        @set-semi-automatic="setSemiAutomaticMode"
      />
    </ToolbarActionsWrapper>
  </main>
</template>

<script>
import ContextMenu from './context-menus/context-menu'
import ContextMenuEdge from './context-menus/context-menu-edge'
import CytoscapeContainer from './cytoscape'
import GlLoader from '@/components/gl-loader'
import GlIcon from '@/components/gl-icon'
import ModalAdd from './modals/modal-add'
import ModalCustomize from './modals/modal-customize'
import ModalFindIntersection from '@/pages/analytics/modals/modal-find-intersection'
import ModalInvalidJson from '@/pages/analytics/modals/modal-invalid-json'
import ModalFindRoute from './modals/modal-find-route'
import ModalCheckSources from '@/pages/analytics/modals/modal-check-sources'
import ToolbarBottom from './toolbars/toolbar-bottom'
import ToolbarRight from './toolbars/toolbar-right'
import ToolbarTop from './toolbars/toolbar-top'
import ContextMenuTacing from "@/pages/analytics/context-menus/context-menu-tacing.vue";
import ToolbarActionsWrapper from "./toolbars/components/ToolbarActionsWrapper.vue";
// Services
import download from 'downloadjs'
import { CytoscapeService } from '@/services/cytoscape'
// Utils
import { delay } from '@/utils/delay'
import { debounce } from '@/utils/debounce'
import { formatDate } from "@/utils/format-date";
import { getDecimalVal } from "@/utils/format-btc-amount";
import { deleteByPath, addByPath } from '@/utils/helpers'
// Vuex
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import ModalCreateCase from "@/pages/analytics/modals/modal-create-case";

import {validate} from "vee-validate";
import ModalLeaveFromAnalytics from "@/pages/analytics/modals/modal-leave-from-analytics";
import {formatBtcAmount} from "@/utils/format-btc-amount";
// Mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'

export default {
  name: 'Analytics',
  components: {
    ModalLeaveFromAnalytics,
    ModalCreateCase,
    ModalCheckSources,
    ContextMenu,
    ContextMenuEdge,
    CytoscapeContainer,
    GlLoader,
    GlIcon,
    ToolbarRight,
    ModalAdd,
    ModalCustomize,
    ModalFindIntersection,
    ModalInvalidJson,
    ModalFindRoute,
    ToolbarBottom,
    ToolbarTop,
    ContextMenuTacing,
    ToolbarActionsWrapper
  },
  mixins: [deviceWidthMixin],
  data() {
    return {
      tabs: [{ label: 'Analytics', value: 'analytics' }, { label: 'History', value: 'history' }],
      activeTab: 'analytics',
      tracingData: {},
      showContextMenuTracing: false,
      showCustomizeModal: false,
      showAddModal: false,
      showFindRouteModal: false,
      showCreateCaseModal: false,
      showFindIntersectionModal: false,
      showCheckSourcesModal: false,
      showContextMenu: false,
      showContextMenuEdge: false,
      contextMenuPosition: { x: 0, y: 0 },
      selectedNode: null,
      selectedElement: {},
      selectedEdge: {},
      txHashListLimit: 25,
      isLoading: false,
      showInvalidJsonModal: false,
      localTxData: [],
      directionEvent: {},
      visualiseLimit: 100,
      positionTarget: {
        x: 0,
        y: 0
      },
      showCyto: false,
      searchMode: 'tx',
      searchEthMode: 'tx',
      viewMode: 'address',
      edgeLabels: {},
      nodeLabels: {},
      semiAutomaticMode: false,
      semiAutomaticModeCount: 1,
      semiAutomaticModeRedoCount: 1,
      ethTracingPerPage: 20,
      leaveFromPage: false,
      leaveFromPageTo: 'analytics',
      caseData: null,
      showMobileToolbarBottom: false,
      ratesToSend: {
        rates: []
      },
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.caseData) {
      next()
      return
    }
    const localExportedGraph = this.saveGraphToJson(false)
    const { elements: localElements } = JSON.parse(localExportedGraph)
    const { elements } = JSON.parse(this.caseData.graph)
    if (this.caseData?._id && elements?.edges?.length !== localElements?.edges?.length) {
      this.leaveFromPage = true
      this.leaveFromPageTo = to.name
      localStorage.removeItem('caseId')
    } else {
      next()
    }
  },
  computed: {
    ...mapState('analytics', ['isHash', 'isAddress', 'coinType', 'searchValue', 'coinData', 'currencyList']),
    ...mapState('cases', ['graphFromCase']),
    ...mapGetters('user', ['getCurrentFiat']),
    ...mapGetters('rates', ['getActualRates']),

    isUnknowMode() {
      return !this.isHash && !this.isAddress
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        if (!to.query.caseId) localStorage.removeItem('caseId')
        if (to.query.caseId || localStorage.getItem('caseId')) {
          const CASE_ID = to.query.caseId || localStorage.getItem('caseId')
          this.isLoading = true
          this.showCyto = true
          this.getCaseById(CASE_ID).then(async ({ success, data }) => {
            if (success) {
              localStorage.setItem('caseId', CASE_ID)
              this.caseData = data

              const { elements: { nodes }, searchMode } = JSON.parse(this.caseData.graph)
              const insertCoinData = this.currencyList.find(coin => coin.label === this.caseData.blockchain) || null

              const addresses = nodes.filter(({ data }) => data.type === 'wallet' || data.type === 'eth').map(({ data }) => data.id)
              const mode = searchMode === 'address' ? 'Exploration' : 'Tracing'
              const caseId = this.caseData._id

              if (to.name === 'analytics' && (!from || from.name === 'analytics')) {
                this.SET_COIN_TYPE(insertCoinData.key)
              }



              this.SET_VALIDATE_HASH(false);
              this.SET_VALIDATE_ADDRESS(true);
              this.SET_SEARCH_TYPE('address')

              validate(data.search, `address:${insertCoinData.key}`, { name: 'Search value' }).then(({ valid }) => {
                if (valid) {
                  this.SET_VALIDATE_HASH(false);
                  this.SET_VALIDATE_ADDRESS(true);
                  this.SET_SEARCH_TYPE('address')
                }
              })

              validate(data.search, 'txHash', {name: 'Search value'}).then(({ valid }) => {
                if (valid) {
                  this.SET_VALIDATE_HASH(true);
                  this.SET_VALIDATE_ADDRESS(false);
                  this.SET_SEARCH_TYPE('tx')
                }
              })

              this.searchMode = this.caseData.key

              this.SET_SEARCH_VALUE(data.search)

              this.cytoscape.setSearchValue(data.search)

              let a = JSON.parse(this.caseData.graph)

              await this.collectPayloadForRates(a?.elements)
              await this.getRates(this.ratesToSend)

              a.elements = await this.addPriceToElements(a.elements)
              
              this.visualCaseUpdates({ addresses, mode, caseId }).then(({ data }) => {
                if (data.newTxs.length > 0) {
                  data.newTxs.forEach((v, i) => {
                    let newTxsData = a.elements.nodes.find(({ data:nodeData }) => nodeData.id === v.address)

                    if (newTxsData) {
                      const localVal = data.newTxs[i]

                      if (localVal.txsNext && Array.isArray(newTxsData.data.nextTxHash)) {
                        newTxsData.data.nextTxHash = newTxsData.data.nextTxHash.concat(localVal.txsNext.map(item => item.tx_hash))
                      }

                      if (localVal.txsPrev && Array.isArray(newTxsData.data.prevTxHash)) {
                        newTxsData.data.prevTxHash = newTxsData.data.prevTxHash.concat(localVal.txsPrev.map(item => item.tx_hash))
                      }

                      this.caseData.graph = JSON.stringify(a)

                      this.cytoscape.cy.json(JSON.parse(this.caseData.graph))
                    }
                  })
                }

                addresses.forEach(el => {

                  if (data.addressInfo.length > 0
                      && a.elements.nodes.find(({ data:nodeData }) => nodeData.id === el
                      && data.addressInfo.find(val => val.addressData.address === el))
                  ) {
                    const localVal = data.addressInfo.find(val => val.addressData.address === el)
                    const findNode = a.elements.nodes.find(({ data:nodeData }) => nodeData.id === el)

                    if (this.coinData.family == 'eth') {
                      if (findNode.data.type === 'eth' && findNode.data) {
                        findNode.data.nodeData.riskScore = localVal.addressData.riskScore
                        findNode.data.nodeData.owner = localVal.addressData.owner
                      }
                    } else{
                      if (findNode.data.type === 'wallet' && findNode.data) {
                        findNode.data.addressData.riskScore = localVal.addressData.riskScore
                        findNode.data.addressData.cluster = localVal.addressData.cluster
                        findNode.data.cluster = localVal.addressData.cluster || localVal.clusterData.cluster
                        findNode.data.addressData.tags = localVal.addressData.tags
                        findNode.data.addressData.type = [localVal.addressData.type]
                        findNode.data.addressData.owner = localVal.addressData.owner || ''
                      }

                      findNode.data.clusterData.riskScore = localVal?.clusterData.riskScore
                      findNode.data.clusterData.cluster = localVal.clusterData.cluster
                      findNode.data.clusterData.tags = localVal.clusterData.tags
                      findNode.data.clusterData.type = [localVal.clusterData.type]
                      findNode.data.clusterData.owner = localVal.clusterData.owner || ''

                      if (findNode.data && findNode.data.cluster) {
                        a.elements.nodes.map(node => {
                          if (node?.data?.clusterData?.cluster == findNode?.data?.clusterData?.cluster) {
                            node.data.clusterData = findNode.data.clusterData
                            return node
                          }

                          return node
                        })
                      }
                    }

                    this.caseData.graph = JSON.stringify(a)

                    this.cytoscape.cy.json(JSON.parse(this.caseData.graph))
                  } else {
                    this.cytoscape.cy.json(JSON.parse(this.caseData.graph))
                  }
                })

                // Update on get new lables
                // if (data.addressInfo.length > 0) {
                //   this.updateExplorerCase(true)
                // }

                this.cytoscape.cy.json(JSON.parse(this.caseData.graph))
              }).finally(() => {
                this.isLoading = false
              })

              this.$nextTick(() => {
                const { edgeLabels, nodeLabels } = JSON.parse(this.caseData.graph)

                localStorage.setItem('label-view', JSON.stringify(nodeLabels))
                localStorage.setItem('edge-view', JSON.stringify(edgeLabels))

                this.changeNodeLabels(nodeLabels)
                this.changeEdgeLabels(edgeLabels)
              })
            }
          })
          return
        }

        if (to.params.openCase) {
          this.isLoading = true
          this.caseData = to.params.caseData
          this.showCyto = true
          this.SET_SEARCH_TYPE(this.graphFromCase.type)
          this.SET_SEARCH_VALUE(this.graphFromCase.search)
          if (this.graphFromCase.type === 'tx') {
            this.SET_VALIDATE_HASH(true);
            this.SET_VALIDATE_ADDRESS(false);
          }

          if (this.graphFromCase.type === 'address') {
            this.SET_VALIDATE_HASH(false);
            this.SET_VALIDATE_ADDRESS(true);
          }
          this.cytoscape.setSearchValue(this.graphFromCase.search)
          this.cytoscape.cy.json(JSON.parse(this.graphFromCase.graph))
          this.isLoading = false
          return
        }

        this.caseData = null

        if (to.name === 'analytics' && (!from || from.name === 'analytics')) {
          if (to.query.type === 'eth' || to.query.type === 'bnb' || to.query.type === 'trx') {
            localStorage.removeItem('graph')
            localStorage.removeItem('caseId')
            this.SET_SEARCH_TYPE('eth')
            this.SET_COIN_TYPE(to.query.type)
            if (to.query.tx) {
              this.SET_SEARCH_VALUE(to.query.tx)
              if (this.cytoscape) {
                this.cytoscape.setSearchValue(to.query.tx)
              }
              this.searchMode = to.query.type
              this.SET_VALIDATE_HASH(true)
              this.SET_VALIDATE_ADDRESS(false)
              this.loadEthData(to.query.tx, true, this.directionData).finally(() => {
                this.centeredGraph()
              })
              return
            } else if (to.query.address) {
              this.SET_SEARCH_VALUE(to.query.address)
              if (this.cytoscape) {
                this.cytoscape.setSearchValue(to.query.address)
              }
              this.searchMode = to.query.type
              this.SET_VALIDATE_HASH(false)
              this.SET_VALIDATE_ADDRESS(true)
              this.loadEthData(to.query.address, true, this.directionData).finally(() => {
                this.centeredGraph()
              })
              return
            }
          }
          if (to.query.tx) {
            localStorage.removeItem('graph')
            localStorage.removeItem('caseId')
            this.SET_SEARCH_TYPE('tx')
            this.SET_SEARCH_VALUE(to.query.tx)
            this.SET_VALIDATE_HASH(true);
            this.SET_VALIDATE_ADDRESS(false);
            if (this.cytoscape) {
              this.cytoscape.setSearchValue(to.query.tx)
            }
            this.searchMode = 'tx'
            return this.getTransactionData(to.query.tx, true, true)
          }
          if (to.query.address) {
            localStorage.removeItem('graph')
            localStorage.removeItem('caseId')
            this.SET_VALIDATE_HASH(false);
            this.SET_VALIDATE_ADDRESS(true);
            this.SET_SEARCH_TYPE('address')
            this.SET_SEARCH_VALUE(to.query.address)
            if (this.cytoscape) {
              this.cytoscape.setSearchValue(to.query.address)
            }
            this.searchMode = 'tx'
            return this.getTransactionData(to.query.address, true, true, true)
          }
        }
        if (this.cytoscape) {
          this.$nextTick(() => {
            this.SET_SEARCH_TYPE(this.cytoscape.searchMode)
            this.SET_SEARCH_VALUE(this.cytoscape.search)

            if (this.cytoscape.searchMode === 'tx') {
              this.SET_VALIDATE_HASH(true)
              this.SET_VALIDATE_ADDRESS(false)
            } else {
              this.SET_VALIDATE_HASH(false)
              this.SET_VALIDATE_ADDRESS(true)
            }
          })
        }

        if (to.name === 'analytics' && !to.query.caseId && localStorage.getItem('caseId')) {
          this.$router.replace({ name: 'analytics', query: { caseId: localStorage.getItem('caseId') } })
        }

        if (!to.query.address && !to.query.tx) {
          this.clearSelectedElement();
        }
      },
    },
  },
  async created() {
    window.addEventListener('resize', this.handleResize)
    if (this.isMobile) {
      this.$root.$on('toggle-toolbar-bottom', (val) => {
        this.showMobileToolbarBottom = val
      })
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
    this.$root.$off("search");
    if (this.isMobile) {
      this.$root.$off("toggle-toolbar-right-shrunk");
    }
  },
  mounted() {
    this.$root.$on('search', this.clearSelectedElement);
    this.changeViewMode(localStorage.getItem('view-mode') || 'address')
    this.changeNodeLabels(JSON.parse(localStorage.getItem('label-view')))
    this.changeEdgeLabels(JSON.parse(localStorage.getItem('edge-view')))
    this.changeFullView(JSON.parse(localStorage.getItem('full-view')))
  },
  updated() {
    this.$root.$on('search', this.clearSelectedElement);
    // this.changeViewMode(localStorage.getItem('view-mode') || 'address')
    this.changeNodeLabels(JSON.parse(localStorage.getItem('label-view')))
    this.changeEdgeLabels(JSON.parse(localStorage.getItem('edge-view')))
  },
  methods: {
    getDecimalVal,
    deleteByPath,
    addByPath,
    ...mapMutations({
      SET_VALIDATE_HASH: 'analytics/SET_VALIDATE_HASH',
      SET_VALIDATE_ADDRESS: 'analytics/SET_VALIDATE_ADDRESS',
      SET_SEARCH_VALUE: 'analytics/SET_SEARCH_VALUE',
      SET_STEPPED_STATE: 'analytics/SET_STEPPED_STATE',
      SET_SEARCH_TYPE: 'analytics/SET_SEARCH_TYPE',
      SET_COIN_TYPE: 'analytics/SET_COIN_TYPE',
      SET_COIN_DATA: 'analytics/SET_COIN_DATA'
    }),
    ...mapActions('analytics', [
      'getTransactionInfo',
      'getNewAddressInfo',
      'getTransactionData',
      'getAddressData',
      'getTxIntersection',
      'getHistory',
      'getTxEthData',
      'getEthAddressData',
      'getPrevNextData',
      'getFilterTracedTxs',
    ]),
    ...mapActions('cases', [
      'createCase',
      'editCase',
      'getCaseById',
      'visualCaseUpdates'
    ]),
    ...mapActions('rates', ['getRates']),
    centeredGraph() {
      this.cytoscape.cy.ready(() => {
        setTimeout(() => {
          this.cytoscape.cy.center(this.cytoscape.cy.elements())
          this.unlockNodes()
        }, 100)
      })
    },
    agreeLeave() {
      this.caseData = null
      this.$router.push({ name: this.leaveFromPageTo })
    },
    toggleFullView(val) {
      if (this.cytoscape) {
        this.cytoscape.toggleFullView(val)
      }
    },
    initCytoscapeService(cy) {
      this.cytoscape = new CytoscapeService(cy)
    },
    virtualizeTransactionsList({ data, address }) {
      data.map(el => this.getFormattedTransactionsData(el))
      this.cytoscape.visualizeNew(address, data , this.directionData)
      this.SET_STEPPED_STATE({ undo: true, redo: false })
      this.changeEdgeLabels(JSON.parse(localStorage.getItem('edge-view')))
    },
    virtualizeEthTransactionsList({ data, search }) {
      const fullView = JSON.parse(localStorage.getItem('full-view'))
      this.cytoscape.visualizeEthData(search, data.txs, {}, data.prevNextInfo)
      this.toggleFullView(fullView)
    },
    clearSelectedElement() {
      this.selectedElement = {}
      localStorage.removeItem('caseId')
    },
    loadEthData: async function (address, reset, origEvent, direction, step = 1) {
      if (reset && this.cytoscape) this.cytoscape.resetGraph()
      const startTimer = performance.now()
      this.isFullView = JSON.parse(localStorage.getItem('full-view'))
      try {
        this.showCyto = true
        this.isLoading = true
        this.isEthLoading = true
        if (address.length < 60) {
          this.searchEthMode = 'address'
          const {success, data: { message, list }} = await this.getEthAddressData({
            address: address,
            prioritize: true,
          })

          if (success && list.length) {
              const { success: gettingCallsStatus, data: { txs: callsTxs, total: callsTotal } } = await this.getTxEthData(
              {
                tx: list[0].hash,
                limit: this.ethTracingPerPage,
                page: 1,
                only: 'calls'
              })

              await this.getTxEthData(
                  {
                      tx: list[0].hash,
                      limit: this.ethTracingPerPage,
                      page: 1,
                      address,
                      only: 'list'
                  }).then(({data, success}) => {
                  if (!success) {
                      this.$toasted.global.error({message: `${data.message}`})
                      if (reset) this.cytoscape.resetGraph()
                      this.isEthLoading = false
                      this.isLoading = false
                      return
                  }
                  const endTimer = performance.now()
                  const res = endTimer - startTimer
                  this.searchEthMode = 'tx'

                  if (gettingCallsStatus && Array.isArray(callsTxs)) {
                    data.txs = data.txs.concat(callsTxs)
                  }

                  data.txs = data.txs.map(item => ({
                      ...item,
                      totalTxs: data.total + callsTotal || 0,
                      prevTx: item.prevTx || [],
                      nextTx: item.nextTx || []
                  }))
                  this.cytoscape.visualizeEthData(list[0].hash, data.txs, this.handleDirection(origEvent), data.prevNextInfo, data)

                  const elData = this.cytoscape.searchElements(address.toLowerCase(), false, true)[0] || null

                  this.selectedElement = elData ? {
                    type: 'address',
                    data: {
                      ...elData.data(),
                      value: address,
                    }
                  } : null

                  const txData = this.cytoscape.searchElements(list[0].hash, false)[0] || null
                  const elType = this.currencyList.find(el => el.path === this.coinType).family

                  this.selectedElement = txData ? {
                      type: elType,
                      data: {
                          ...txData.data(),
                          value: address,
                      }
                  } : null

                  const onlyLogs = !data.txs.find(el => el.value > 0 || (el.valueEth && el.valueEth > 0))

                  if (onlyLogs) {
                      this.isFullView = true
                      localStorage.setItem('full-view', this.isFullView)
                  }

                  const clickedNodeId = origEvent.target.data('id')
                  const a = this.cytoscape.searchElements(clickedNodeId ? clickedNodeId : address, false)
                  !(a && a.length > 0)
                      ? this.selectedElement = null
                      : this.selectedElement = {
                          type: this.coinData.key,
                          data: {
                              ...a[0].data(),
                              value: list[0].hash,
                          }
                      }
                  if (this.semiAutomaticMode && !reset && direction !== undefined && res < 2000) {
                      this.handleSemiAutomaticSearch(data.txs, step, direction)
                  }
                  if (origEvent.target.data('id')) {
                      this.cytoscape.replaceTx(origEvent.target.data('id'), list[0].hash)
                  }
                  this.cytoscape.checkTxOnGraph()
              }).catch(({response: {data}}) => {
                  this.$toasted.global.error({message: `${data.message}`})
                  if (reset) this.cytoscape.resetGraph()
                  this.isEthLoading = false
                  this.isLoading = false
              }).finally(() => {
                  this.isEthLoading = false
                  // this.isLoading = false
                  this.changeFullView(this.isFullView)
              })
          }

          if (!success) this.$toasted.global.error({message: `${message}`})
          this.isEthLoading = false
          this.isLoading = false
          return
        } else {
          if (reset && this.cytoscape) this.cytoscape.resetGraph()

          const { success: gettingCallsStatus, data: { txs: callsTxs, total: callsTotal } } = await this.getTxEthData(
            {
              tx: address,
              limit: this.ethTracingPerPage,
              page: 1,
              only: 'calls'
            })

          await this.getTxEthData(
              {
                tx: address,
                limit: this.ethTracingPerPage,
                page: 1,
                only: 'list'
              }).then(({data, success}) => {
            if (!success) {
              this.$toasted.global.error({message: `${data.message}`})
              if (reset) this.cytoscape.resetGraph()
              this.isEthLoading = false
              this.isLoading = false
              return
            }
            const endTimer = performance.now()
            const res = endTimer - startTimer
            this.searchEthMode = 'tx'

            if (gettingCallsStatus && Array.isArray(callsTxs)) {
              data.txs = data.txs.concat(callsTxs)
            }

            data.txs = data.txs.map(item => ({
              ...item,
              totalTxs: data.total + callsTotal || 0,
              prevTx: item.prevTx || [],
              nextTx: item.nextTx || []
            }))
            this.cytoscape.visualizeEthData(address, data.txs, this.handleDirection(origEvent), data.prevNextInfo, data)

            const txData = this.cytoscape.searchElements(address, false)[0] || null
            const elType = this.currencyList.find(el => el.key === this.coinType).key

            this.selectedElement = txData ? {
              type: elType,
              data: {
                ...txData.data(),
                value: address,
              }
            } : null

            const onlyLogs = !data.txs.find(el => el.value > 0 || (el.valueEth && el.valueEth > 0))

            if (onlyLogs) {
              this.isFullView = true
              localStorage.setItem('full-view', this.isFullView)
            }

            const clickedNodeId = origEvent.target.data('id')
            const a = this.cytoscape.searchElements(clickedNodeId ? clickedNodeId : address, false)
            !(a && a.length > 0)
                ? this.selectedElement = null
                : this.selectedElement = {
                  type: this.coinData.key,
                  data: {
                    ...a[0].data(),
                    value: address,
                  }
                }
            if (this.semiAutomaticMode && !reset && direction !== undefined && res < 2000) {
              this.handleSemiAutomaticSearch(data.txs, step, direction)
            }
            if (origEvent.target.data('id')) {
              this.cytoscape.replaceTx(origEvent.target.data('id'), address)
            }
            this.cytoscape.checkTxOnGraph()
          }).catch(({response: {data}}) => {
            this.$toasted.global.error({message: `${data.message}`})
            if (reset) this.cytoscape.resetGraph()
            this.isEthLoading = false
            this.isLoading = false
          }).finally(() => {
            this.isEthLoading = false
            // this.isLoading = false
            this.changeFullView(this.isFullView)
          })
        }
      } catch (error) {
        // if (reset) this.cytoscape.resetGraph()
        // this.showCyto = false
        this.isEthLoading = false
        this.isLoading = false
      }
    },
    setSelectedElementData(fundedNode) {
      if (fundedNode && fundedNode.data && fundedNode.data.type === 'wallet') {
        this.$root.$emit('open-node')
        this.selectedElement = {
          type: 'address',
          value: fundedNode.data.id,
          data: {
            cluster: fundedNode.data.cluster,
            clusterData: fundedNode.data.clusterData || null,
          },
        }
      } else this.selectedElement = {}
    },
    async handleDoubleClick(e, event) {
      if (e.target.data('coinbase')) {
        return
      }

      if (this.coinData.family === 'eth') {
        await this.handleClickEthMode(e, event)
      } else {
        await this.handleClickTxMode(e)
      }
    },
    async handleClick(e) {
      this.handleElementSelect(e)
      this.positionTarget = e.position
      this.handleDirection(e)
    },
    handleDirection(e) {
      try {
        let source = {
          x: this.searchMode === 'address'
            ? Math.round(e.cy.nodes(`#${ e.target.data('id') }`).position().x)
            : Math.round(e.cy.nodes(`#${ e.cy.edges().data().source }`).position().x),
          y: this.searchMode === 'address'
            ? Math.round(e.cy.nodes(`#${ e.target.data('id') }`).position().y)
            : Math.round(e.cy.nodes(`#${ e.cy.edges().data().source }`).position().y)
        }

        if (e.x && e.y) {
          source.x = e.x
          source.y = e.y
        }

        const target = {
          x: Math.round(e?.position?.x || this.positionTarget.x),
          y: Math.round(e?.position?.y || this.positionTarget.y)
        }

        const fi = 100
        // Left-Top
        if ((source.x > target.x) && (source.y > target.y)) {
          this.directionData = { position: { x: target.x - fi, y: target.y - fi }}
          return { position: { x: target.x - fi, y: target.y - fi }}
        }

        // Right-Top
        if ((source.x < target.x) && (source.y > target.y)) {
          this.directionData = { position: { x: target.x + fi, y: target.y - fi }}
          return { position: { x: target.x + fi, y: target.y - fi }}
        }

        //Right-Bottom
        if ((source.x < target.x) && (source.y < target.y)) {
          this.directionData = { position: { x: target.x + fi, y: target.y + fi }}
          return { position: { x: target.x + fi, y: target.y + fi }}
        }

        //left-Bottom
        if ((source.x > target.x) && (source.y < target.y)) {
          this.directionData = { position: { x: target.x - fi, y: target.y + fi }}
          return { position: { x: target.x - fi, y: target.y + fi }}
        }

        return { position: { x: 0, y: 0 }}
      } catch (e) {
        return { position: { x: 0, y: 0 }}
      }
    },
    tracingTxDataFormatter(tx) {
      return {
        ...tx.data(),
        formattedAmount: formatBtcAmount(
            this.getDecimalVal(tx.data('additionalData'), tx.data('additionalData').value) || this.getDecimalVal(tx.data('additionalData'), tx.data('additionalData').valueEth) || 0,
            true,
          this.coinData.family,
            tx.data('contract')
        ),
        tx_hash: tx.data('txHash'),
        txId: tx.data('additionalData')._id,
        parent_tx_hash: tx.data('additionalData').parent_tx_hash || tx.data('additionalData').tx_hash,
        timestamp: tx.data('additionalData').timestamp,
        isLog: tx.data('additionalData').isLog,
        value: tx.data('additionalData').value || 0,
        valueEth: tx.data('additionalData').valueEth || 0,
        address: tx.data('additionalData').address,
        blockNumber: tx.data('additionalData').blockHeight,
      }
    },
    async handleTracing(item, ITXPrevNext, direction) {
      this.isLoading = true
      if (direction === 'prevTx') {
        ITXPrevNext[direction] = [item]
        ITXPrevNext['nextTx'] = []
      } else {
        ITXPrevNext[direction] = [item]
        ITXPrevNext['prevTx'] = []
      }

      if (item.nodeAddress) {
        ITXPrevNext['sortByFromTo'] = item.nodeAddress
      }

      const elData = this.cytoscape.searchElement(item.nodeAddress, false, false)

        await this.getPrevNextData(ITXPrevNext).then(({ data: { prev, next } }) => {
          if (prev?.prevFullTxs?.length <= this.txHashListLimit || prev?.prevFullTxs?.length && confirm(`Over then ${this.txHashListLimit} prev txs. Open?`)) {
            this.traceByDirection('prev', item, elData, prev)
          }

          if (next?.nextFullTxs?.length <= this.txHashListLimit || next?.nextFullTxs?.length && confirm(`Over then ${this.txHashListLimit} next txs. Open?`)) {
            this.traceByDirection('next', item, elData, next)
          }

          // Temp solution
          // if (prev?.prevFullTxs?.length === 0 && next?.nextFullTxs?.length === 0) {
          //   elData.data('nodeData').nextTx = []
          //   elData.data('nodeData').prevTx = []
          // }
        }).finally(() => {
          this.isLoading = false
        })
    },
    traceByDirection(direction = 'next', tracedItem, itemGraphData, traceData) {
      traceData[`${direction}FullTxs`].forEach(({ calls = [], txs, prevNextInfo, total }) => {
        const assumedDataList = Array.isArray(calls) && Array.isArray(txs) ? txs.concat(calls) : txs || []
        txs = assumedDataList.map((tx) => ({
          ...tx,
          totalTxs: total,
        }))
        this.cytoscape.visualizeEthData(tracedItem.nodeAddress, txs, this.cytoscape.searchElement(tracedItem.nodeAddress).position(), prevNextInfo)
        itemGraphData.data('nodeData')[`${direction}Tx`] = []
        this.cytoscape.checkFullTxsListOnGraph(tracedItem.tx_hash)
      })
    },
    checkBySelfTransactions(tx) {
      return this.cytoscape.searchElements(tx.tx_hash).length
        && this.cytoscape.searchElements(tx.tx_hash).data('sourceAddress') === tx.nodeAddress
        && this.cytoscape.searchElements(tx.tx_hash).data('targetAddress') === tx.nodeAddress
    },
    async handleClickEthMode(e, originEvent) {
      if (Object.keys(e.target.data()).length === 0) return
      if (e.target.data().type === 'tx') {
        this.$nextTick(() => {
          this.searchElementsOnGraph(e.target.data('txHash'), false)
        })
        return
      }
      if (e.target.data('nodeData') && !new RegExp(this.coinData.addressRegex).test(e.target.id())) {
        return
      }
      if (!e.target.data().nodeData.disabled && (e.target.data().nodeData.prevTx.length || e.target.data().nodeData.nextTx.length)) {
        const { x, y } = originEvent.renderedPosition
        this.contextMenuPosition = { x, y }

        const nodeEdges = this.cytoscape.cy.nodes(`#${e.target.data('id')}`).connectedEdges()

        const ITXPrevNext = {
          prevTx: nodeEdges
              .filter(el => el.data('source') === e.target.id() && el.data('amount') > 0 && el.data('additionalData').prevTx)
              .map(tx => ({
                ...this.tracingTxDataFormatter(tx),
                nodeAddress: e.target.id(),
              })),
          nextTx: nodeEdges
              .filter(el => el.data('target') === e.target.id() && el.data('amount') > 0 && el.data('additionalData').nextTx)
              .map(tx => ({
                ...this.tracingTxDataFormatter(tx),
                nodeAddress: e.target.id(),
              })),
        }

        await this.getFilterTracedTxs(ITXPrevNext).then(({ success, data }) => {
          if (success) {
            if (!data.prevTx.length && !data.nextTx.length ) {
              e.target.data('nodeData').nextTx = []
              e.target.data('nodeData').prevTx = []
              this.tracingData.nextTx = []
              this.tracingData.prevTx = []

              const el = this.cytoscape.searchElement(e.target.id())

              if (el) {
                el.deselect()
              }

              return
            }
            this.tracingData = data
            if (this.tracingData.nextTx.concat(this.tracingData.prevTx).length === 1) {
              if (this.tracingData.nextTx.length === 1) {
                this.handleTracing(this.tracingData.nextTx[0], this.tracingData, 'nextTx')
              } else {
                this.handleTracing(this.tracingData.prevTx[0], this.tracingData, 'prevTx')
              }
            } else if (this.tracingData.nextTx.concat(this.tracingData.prevTx).length > 0) {
              this.showContextMenuTracing = true
            }
          } else {
            this.tracingData.nextTx = []
            this.tracingData.prevTx = []
          }
        })

        // Temp commented for preparing next iteration feature
        // if (e.target.data('nodeData').prevTx.length > 0 || e.target.data('nodeData').nextTx.length > 0) {
        //   await this.getPrevNextData(ITXPrevNext).then(({ data: { prev, next } }) => {
        //     if (prev?.prevFullTxs?.length <= this.txHashListLimit || prev?.prevFullTxs?.length && confirm(`Over then ${this.txHashListLimit} prev txs. Open?`)) {
        //       this.isLoading = true
        //
        //       prev.prevFullTxs.forEach(({ txs, prevNextInfo }) => {
        //         this.cytoscape.visualizeEthData(e.target.data('id'), txs, this.handleDirection(e), prevNextInfo)
        //         e.target.data('nodeData').prevTx = []
        //       })
        //
        //       this.isLoading = false
        //     }
        //
        //     if (next?.nextFullTxs?.length <= this.txHashListLimit || next?.nextFullTxs?.length && confirm(`Over then ${this.txHashListLimit} next txs. Open?`)) {
        //       this.isLoading = true
        //
        //       next.nextFullTxs.forEach(({ txs, prevNextInfo }) => {
        //         this.cytoscape.visualizeEthData(e.target.data('id'), txs, this.handleDirection(e), prevNextInfo)
        //         e.target.data('nodeData').nextTx = []
        //       })
        //
        //       this.isLoading = false
        //     }
        //
        //     if (prev?.prevFullTxs?.length === 0 && next?.nextFullTxs?.length === 0) {
        //       e.target.data('nodeData').nextTx = []
        //       e.target.data('nodeData').prevTx = []
        //     }
        //   })
        // } else {
        //     const canOpenPrev = true;
        //     const canOpenNext = true;
        //     if (e.target.data('nodeData').prevTx.length > 0 && !this.cytoscape.hasIncomers(e.target.data('id')) && canOpenPrev) {
        //       this.isLoading = true
        //       await Promise.all(e.target.data('nodeData').prevTx.map(async ({ tx_hash }, index) => {
        //         if (index + 1 <= this.ethTracingPerPage) {
        //           await this.loadEthData(tx_hash, false, e, false)
        //         }
        //       }))
        //       this.isLoading = false
        //       return
        //     } else {
        //       e.target.data().nodeData.clicked = false
        //       this.cytoscape.checkNonTxsClass(e.target)
        //     }
        //   if (e.target.data('nodeData').nextTx.length > 0 && !this.cytoscape.hasOutgoers(e.target.data('id')) && canOpenNext) {
        //     e.target.data().nodeData.clicked  = true
        //     this.cytoscape.cy.nodes(`#${e.target.data('id')}`).map(el => {
        //       el.addClass('nonTxs')
        //     })
        //     this.isLoading = true
        //     await Promise.all(e.target.data('nodeData').nextTx.map(async ({ tx_hash }, index) => {
        //       if (index + 1 <= this.ethTracingPerPage) {
        //         await this.loadEthData(tx_hash, false, e, true)
        //       }
        //     }))
        //     this.isLoading = false
        //     return
        //   } else {
        //     e.target.data().nodeData.clicked = false
        //     this.cytoscape.checkNonTxsClass(e.target)
        //   }
        //   if (this.cytoscape.hasOutgoers(e.target.data('id')) && this.cytoscape.hasIncomers(e.target.data('id'))) {
        //     e.target.data().nodeData.clicked  = true
        //     this.isLoading = true
        //     const assumeHashes = e.target.data('nodeData').prevTx.concat(e.target.data('nodeData').nextTx)
        //     await Promise.all(assumeHashes.map(async (txHash, index) => {
        //       if (index + 1 <= this.ethTracingPerPage) {
        //         await this.loadEthData(txHash, false, e)
        //       }
        //     }))
        //     this.isLoading = false
        //   }
        // }
      }
    },
    // First version
    async handlePrevTxHash(e, step = 1) {
      const prevTxHash = e.target.data('prevTxHash') ? [...new Set(e.target.data('prevTxHash'))] : []
      const address = e.target.data('id')

      if (Array.isArray(prevTxHash)) {
      const canOpen = prevTxHash.length <= this.txHashListLimit || (prevTxHash.length > this.txHashListLimit && confirm(`Over then ${this.txHashListLimit} txs. Open?`));
        if (canOpen) {
          if (prevTxHash.length > 0) {
            this.semiAutomaticModeCount = 0
          }
          for(let i=0; i <= prevTxHash.length; i++) {
            if (prevTxHash[i]) {
              this.semiAutomaticModeCount = this.semiAutomaticModeCount + 1
            }
            prevTxHash[i] && (await this.getTransactionInfo({ txHash: prevTxHash[i], only: 'inputs', sortByAddress: address }).then(({ data }) => {
              const formattedData = this.getFormattedTransactionsData(data);
              // this.cytoscape.visualizeTransactionData(formattedData, this.directionData)
              this.$root.$emit('open-node')
              this.SET_STEPPED_STATE({ undo: true, redo: false })
              this.cytoscape.visualizePreviousTransactionData(e.target, formattedData, this.handleDirection(e))
              if (this.semiAutomaticMode && prevTxHash.length === 1) this.handleSemiAutomaticSearch(formattedData.inputs, step, e, false)
            }))
          }
        }
      } else {
        if (prevTxHash) {
          await this.getTransactionInfo({ txHash: prevTxHash, only: 'inputs', sortByAddress: address }).then(({ data }) => {
            const formattedData = this.getFormattedTransactionsData(data);
            // this.cytoscape.visualizeTransactionData(formattedData, this.directionData)
            this.$root.$emit('open-node')
            this.SET_STEPPED_STATE({ undo: true, redo: false })
            this.cytoscape.visualizePreviousTransactionData(e.target, formattedData, this.handleDirection(e))
            if (this.semiAutomaticMode) this.handleSemiAutomaticSearch(formattedData.inputs, step, e, false)
          })
        }
      }
    },
    async handleNextTxHash(e, step = 1) {
      const nextTxHash = e.target.data('nextTxHash') ? [...new Set(e.target.data('nextTxHash'))] : []
      const address = e.target.data('id')

      if (Array.isArray(nextTxHash)) {
      const canOpen = nextTxHash.length <= this.txHashListLimit || (nextTxHash.length > this.txHashListLimit && confirm(`Over then ${this.txHashListLimit} txs. Open?`));
        if (canOpen) {
          if (nextTxHash.length > 0) {
            this.semiAutomaticModeCount = 0
          }
          for(let i=0; i <= nextTxHash.length; i++) {
            if (nextTxHash[i]) {
              this.semiAutomaticModeCount = this.semiAutomaticModeCount + 1
            }
            nextTxHash[i] && (await this.getTransactionInfo({ txHash: nextTxHash[i], only: 'outputs', sortByAddress: address }).then(({ data }) => {
              const formattedData = this.getFormattedTransactionsData(data);
              // this.cytoscape.visualizeTransactionData(formattedData, this.directionData)
              let bigData = [data.outputs]
              bigData = bigData.flat().length
              // this.cytoscape.visualizeTransactionData(formattedData, this.directionData)
              if (bigData <= this.visualiseLimit || (bigData > this.visualiseLimit)) {
                this.$root.$emit('open-node')
                this.SET_STEPPED_STATE({ undo: true, redo: false })
                this.cytoscape.visualizeNextTransactionData(e.target, formattedData, this.handleDirection(e))
                if (this.semiAutomaticMode && nextTxHash.length === 1) this.handleSemiAutomaticSearch(formattedData.outputs, step, e, true)
              }
            }))
          }
        }
      } else {
        if (nextTxHash) {
          await this.getTransactionInfo({ txHash: nextTxHash, only: 'outputs', sortByAddress: address }).then(({ data }) => {
            const formattedData = this.getFormattedTransactionsData(data);
            let bigData = [data.outputs]
            bigData = bigData.flat().length
            // this.cytoscape.visualizeTransactionData(formattedData, this.directionData)
            if (bigData <= this.visualiseLimit || (bigData > this.visualiseLimit)) {
              this.$root.$emit('open-node')
              this.SET_STEPPED_STATE({ undo: true, redo: false })
              this.cytoscape.visualizeNextTransactionData(e.target, formattedData, this.handleDirection(e))
              if (this.semiAutomaticMode) this.handleSemiAutomaticSearch(formattedData.outputs, step, e, true)
            }
          })
        }
      }
    },
    handleSemiAutomaticSearch(data, step, e, isNextDirection = true) {
      this.semiAutomaticModeCount = step
      if (data.length > 5 || step > 10) return
      if (data.some(ele =>
          (ele.clusterData && ele.clusterData.owner)
          || (ele.clusterData?.type?.length > 0)
          || (ele.clusterData?.tags?.length > 0)
          || (ele.clusterData?.description)
          || (ele.clusterData && ele.clusterData.addressCount && ele.clusterData.addressCount >= 500)
          || (ele.addressData?.type?.length > 0)
          || (ele.addressData?.tags?.length > 0)
          || (ele.addressData?.owner)
          || (ele.addressData?.description)
          || (ele.addressData?.txReceivedCount >= 1000)
          || (ele.addressData?.txSentCount >= 1000)
      )) return

      const [firstOut] = data.sort((a, b) => b.amount - a.amount)
      const node = this.cytoscape.cy.nodes(`#${ firstOut.address }`).first()

      return isNextDirection
          ? this.handleNextTxHash({ ...e, target: node }, step + 1)
          : this.handlePrevTxHash({ ...e, target: node }, step + 1)
    },
    async handleClickTxMode(e) {
      if (!e.target.data('amountA')) return
      this.isLoading = true
      await this.handlePrevTxHash(e)
      await this.handleNextTxHash(e)
      this.isLoading = false
    },
    async handleClickAddressMode(e) {
      if (e.target.data('type') === 'wallet') {
        try {
          const { data: { list:newList } } = await this.getNewAddressInfo({address: e.target.id()})

          let bigData = []

          newList.forEach(item => {
            bigData.push(item.inputs, item.outputs)
          })

          const renderedIds = this.cytoscape.cy.nodes().map(node => node.id())
          const filteredArray = bigData.flat().filter(value => !renderedIds.includes(value.address)) || []

          this.$root.$emit('open-node')
          this.SET_STEPPED_STATE({ undo: true, redo: false })

          if (filteredArray.length <= this.visualiseLimit || (filteredArray.length > this.visualiseLimit)) {
            this.cytoscape.visualizeNew(e.target.id(), newList, this.handleDirection(e), true, false)
          }
        } catch (e) {
          console.log(e)
        }
      }
    },
    handleContextClick(e) {
      const { offsetX: x, offsetY: y } = e.originalEvent
      this.contextMenuPosition = { x, y }
      if (this.$can('use', 'eth')) {
        this.showContextMenu = true
        this.showContextMenuEdge = false
        this.selectedNode = {
          id: e.target.id(),
          isLocked: e.target.locked(),
        }
      }
      if (e.target.data('type') === 'wallet') {
        this.showContextMenu = true
        this.showContextMenuEdge = false
        this.selectedNode = {
          id: e.target.id(),
          isLocked: e.target.locked(),
          description: e.target.data('description'),
          color: e.target.data('color'),
        }
      }
      // Temp
      // if (e.target.data('type') === 'cluster') {
      //   this.cytoscape.collapseCluster(e.target)
      // }
      if (e.target.data('type') === 'tx') {
        this.showContextMenuEdge = true
        this.showContextMenu = false
        this.selectedEdge = {
          id: e.target.id(),
          description: e.target.data(),
        }
      }
    },
    resetVision() {
      this.cytoscape.resetGraph()
      this.showCyto = false
      this.SET_VALIDATE_HASH(false);
      this.SET_VALIDATE_ADDRESS(false);
    },
    handleResize() {
      debounce(() => this.cytoscape.resize(), 500)
    },
    async getAddressDataInfo(address, reset, fit) {
      try {
        this.showCyto = true
        this.isLoading = true
        const { success, data: { list:newList, backward, forward } } = await this.getNewAddressInfo({address: address})

        if (!success) this.$toasted.global.error({ message: 'Search value is not valid' })
        this.getAddressData({ address }).then(({ data }) => {
          this.selectedElement = {
            type: 'address',
            value: data.address || (data.clusterData && data.clusterData.address) || address,
            data: {
              cluster: data.cluster,
              clusterData: data.clusterData || null,
            },
            addressListData: {
              dataList: newList || null,
              backward,
              forward,
            }
          }
        })

        if (reset) this.cytoscape.resetGraph()

        let bigData = []
        let formattedList = []

        formattedList.push(newList[0])

        if (formattedList.length) {
          bigData.push(newList[0].inputs, newList[0].outputs)
          bigData = bigData.flat().length
        }

        if (bigData <= this.visualiseLimit || (bigData > this.visualiseLimit)) {
          this.cytoscape.setSearchMode(this.searchMode)
          this.cytoscape.visualizeNew(address, formattedList, {}, true, fit)
          this.changeViewMode(localStorage.getItem('view-mode') || 'address')
        } else {
          // this.resetVision()
          this.cytoscape.resetGraph()
        }

      } catch (error) {
        console.log(error, 'visualizeNew')
        if (reset) this.cytoscape.resetGraph()
        this.showCyto = false
      } finally {
        this.isLoading = false
      }
    },
    async getTransactionData(txHash, reset, fit, isAddress) {
      try {
        this.showCyto = true
        this.isLoading = true
        const { success, data } = await this.getTransactionInfo({ txHash, isAddress })
        if (!success) this.$toasted.global.error({ message: `${data.message}` })
        this.localTxData = data;
        if (reset) this.cytoscape.resetGraph()

        const formattedData = this.getFormattedTransactionsData(data);
        this.cytoscape.setSearchMode(this.searchMode)

        this.cytoscape.visualizeTransactionData(formattedData, {}, fit)
        this.changeViewMode(localStorage.getItem('view-mode') || 'address')

        if (isAddress) {
          this.selectedElement = {
            type: 'address',
            value: data.address || (data.clusterData && data.clusterData.address) || txHash,
            data: {
              cluster: data.cluster,
              clusterData: data.clusterData || null,
            },
          }
        } else {
          this.selectedElement = {
            type: 'tx',
            value: data.tx_hash,
            data,
          }
          if (data.tx_hash) {
            this.cytoscape.searchElements(data.tx_hash)
          }
        }

      } catch (error) {
        if (reset) {
          this.cytoscape.resetGraph()
          this.showCyto = false
        }
      } finally {
        this.isLoading = false
      }

    },
    getFormattedTransactionsData(data) {
      // data.inputs = this.combineTxAddressesAndCalculateAmount(data.inputs, 'prev_tx_hash')
      // data.outputs = this.combineTxAddressesAndCalculateAmount(data.outputs, 'next_tx_hash')
      return data
    },
    combineTxAddressesAndCalculateAmount(addresses, txsField) {
      return addresses.filter((v, i) => {
        const index = addresses.map(mv => mv.address).indexOf(v.address);
        const same = index === i;
        if (!same) addresses[index].amount += v.amount;

        if (addresses[index][txsField]) {
          if(!Array.isArray(addresses[index][txsField]))
          addresses[index][txsField] = [v[txsField]];
          else addresses[index][txsField].push(v[txsField])
        }

        return same;
      });
    },
    changeViewMode(viewMode) {
      if (this.cytoscape) {
        this.cytoscape.setViewMode(viewMode)
        this.viewMode = viewMode
      }
    },
    changeZoom(mode) {
      this.cytoscape.changeZoom(mode)
    },
    changeNodeLabels(val) {
      if (this.cytoscape) {
        this.nodeLabels = val
        this.cytoscape.setNodeLabels(val)
      }
    },
    changeEdgeLabels(val) {
      if (this.cytoscape) {
        this.edgeLabels = val
        this.cytoscape.setEdgeLabels(val)
      }
    },
    changeFullView(fullView) {
      if (this.cytoscape) {
        this.cytoscape.toggleFullView(fullView)
      }
    },
    hideNodes(selector) {
      this.cytoscape.hideNodes(selector)
      // this.changeViewMode(this.cytoscape.viewMode)
      this.SET_STEPPED_STATE({ undo: true, redo: false })
    },
    showHidden() {
      this.cytoscape.showHiddenNodes()
      // this.changeViewMode(this.cytoscape.viewMode)
    },
    lockNodes(selector) {
      this.cytoscape.lockNodes(selector)
    },
    unlockNodes(selector) {
      this.cytoscape.unlockNodes(selector)
    },
    updateExplorerCase(isSoftUpdate = false) {
      const localExportedGraph = this.saveGraphToJson(false)
      // eslint-disable-next-line no-case-declarations
      this.cytoscape.exportToBase64().then((res) => {
        this.editCase({
          id: this.caseData._id,
          title: this.caseData.title,
          graph: localExportedGraph,
          image: res
        }).then(({success, data}) => {
          if (!isSoftUpdate) {
            this.$toasted.global.success({ message: `Case updated successfully` })
          }
          if (success && data && data.graph) {
            this.$set(this.caseData, 'graph', data.graph)
            this.cytoscape.cy.json(JSON.parse(this.caseData.graph))
          } else {
            this.caseData.graph = localExportedGraph
          }
          // this.changeViewMode(localStorage.getItem('view-mode') || 'address')
        })
      })
    },
    async exportGraph(type) {
      switch (type) {
        case 'png':
          download(await this.cytoscape.exportToPng(), 'graph.png', `png`)
          break
        case 'csv':
          download(this.cytoscape.exportToCsv(), 'graph.csv', `csv`)
          break
        case 'json':
          download(this.saveGraphToJson(), 'graph.json', 'json')
          break
        case 'cases':
          this.setCaseTitle()
          break
        default:
          break
      }
    },
    setCaseTitle() {
      this.showCreateCaseModal = true
    },
    getBlockchain() {
      return this.coinData.label
    },
    saveCase(title) {
      const localExportedGraph = this.saveGraphToJson(false)
      // eslint-disable-next-line no-case-declarations
      this.cytoscape.exportToBase64().then((res) => {
        this.createCase({
          search: this.searchValue,
          graph: localExportedGraph,
          blockchain: this.getBlockchain(),
          title: title || 'Case',
          image: res
        }).then(({ success, data }) => {
          if (success) {
            this.$toasted.global.success({ message: `Case saved successfully` })
            this.showCreateCaseModal = false
            this.$refs.createCaseModal.value = ''
            localStorage.setItem('caseId', data.insertedId)
            this.$router.push({ name: 'analytics', query: { ...this.$route.query, caseId: data.insertedId } })
          } else {
            this.$toasted.global.error({ message: data.message })
          }
        })
      })
    },
    saveGraphToJson(deletePrice = true) {
      const { elements, pan, zoom } = this.cytoscape.exportToJson()
      let filteredElements
      if(deletePrice) {
        filteredElements = this.deletePriceFromElements(elements)
      }
      return JSON.stringify({
        elements: deletePrice ? filteredElements : elements,
        pan,
        zoom,
        searchValue: this.searchValue,
        searchMode: this.searchMode || this.coinData.key,
        viewMode: this.cytoscape.viewMode,
        nodeLabels: this.nodeLabels,
        edgeLabels: this.edgeLabels,
        caseCoinData: this.coinData
      })
    },
    deletePriceFromElements(elements){
      try {
        if (elements.edges && elements?.edges?.length) {
          elements.edges = elements.edges.map(edge => this.deleteByPath(edge, 'data.price'))
          if (this.coinData.family == 'eth') {
            elements.edges = elements.edges.map(edge => this.deleteByPath(edge, 'data.additionalData.valuePrice'))
          }
        }
        if (elements.nodes && elements.nodes.length) {
          if (this.coinData.family == 'eth') {
            elements.nodes = elements.nodes.map(node => this.deleteByPath(node, 'data.nodeData.valuePrice'))
          } else {
            elements.nodes = elements.nodes.map(node => this.deleteByPath(node, 'data.txsList[]price'))
            elements.nodes = elements.nodes.map(node => this.deleteByPath(node, 'data.inputData.price'))
          }
        }
      } catch (error) {
        console.error(error)
      }

      return elements
    },
    async addPriceToElements(elements) {
      try {
        if (elements.edges && elements?.edges?.length) {
          elements.edges = await Promise.all(elements.edges.map(async (edge) => {
            const value = this.findClosestRatesTimestamp(edge.data?.additionalData?.contractData?.address, edge.data?.additionalData?.valuePriceTimestamp || this.padTimestamp(edge.data?.sourceTimestamp))
            
            if (this.coinData.family == 'eth') {
              return this.addByPath(edge, 'data.price', value ? value.price * edge.data?.amount : null)
            } else {
              return this.addByPath(edge, 'data.price', value ? value.price * this.getDecimalVal(this.coinType, edge.data.amount) : null)
            }
          }))

          if (this.coinData.family == 'eth') {
            elements.edges = await Promise.all(elements.edges.map(async (edge) => {
              const value = this.findClosestRatesTimestamp(edge.data?.additionalData?.contractData?.address, this.padTimestamp(edge.data?.additionalData?.valuePriceTimestamp))
              return this.addByPath(edge, 'data.additionalData.valuePrice', value ? value.price * edge.data.amount : null)
            }))
          }
        }
      } catch (error) {
        console.error(error)
      }

      return elements
    },
    collectPayloadForRates(elements) {
      return new Promise((resolve, reject) => {
        try {
          const uniqueRates = new Set()
          this.ratesToSend.rates = []

          elements.edges.forEach(edge => {
            if (edge?.data?.additionalData?.valuePriceTimestamp || edge?.data?.sourceTimestamp) {
              const rate = {
                coin: this.coinType,
                contract: edge.data?.additionalData?.contractData?.address || '',
                from: edge.data?.contract || this.coinType.toUpperCase(),
                to: this.getCurrentFiat,
                timestamp: this.padTimestamp(edge?.data?.additionalData?.valuePriceTimestamp || edge?.data?.sourceTimestamp, edge),
              }

              const rateKey = `${rate.from}-${rate.to}-${rate.timestamp}`

              if (!uniqueRates.has(rateKey)) {
                uniqueRates.add(rateKey)
                this.ratesToSend.rates.push(rate)
              }
            }
          })

          resolve()
        } catch (error) {
          reject(error)
        }
      })
    },
    searchElementsOnGraph(value, zoom) {
      if (!value) return
      this.cytoscape.searchElements(value, zoom)
      if (this.cytoscape.searchElements(value, zoom).length === 0) {
        this.$toasted.global.info({ message: 'Unable to find a route'})
      }
    },
    async handleElementSelect(e) {
      if (e.target.data('searchType') === 'eth' || e.target.data('type') === 'eth') {

        if (e.target.data('nodeData') && !new RegExp(this.coinData.addressRegex).test(e.target.id())) {
          return
        }

        this.selectedElement = {
          value: e.target.id(),
          data: e.target.data(),
          type: 'eth',
        }
        return
      }

      if (e.target.data('coinbase')) {
        return
      }

      if (e.target.data('type') === 'wallet') {
        if (!e.target.data('amountA')) {
          return
        }

        this.selectedElement = {
          value: e.target.id(),
          data: e.target.data(),
          totalAmount: this.localTxData.inputsAmount || 0,
          totalAmountPrice: this.localTxData && this.localTxData.inputsAmountPrice || 0,
          totalAmountPriceTimestamp: this.localTxData && this.localTxData.inputsAmountPriceTimestamp || null,
          type: 'address',
          clusterData: this.searchMode === 'tx' && e.target.data('clusterData'),
        }
        return
      }
      if (e.target.data('type') === 'tx') {
        if (!e.target.data('amount')) {
          return
        }

        this.$nextTick(() => {
          this.searchElementsOnGraph(e.target.data('txHash'), false)
        })
        const { data } = await this.getTransactionInfo({ txHash: e.target.data('txHash') })
        this.localTxData = data;
        this.selectedElement = {
          value: e.target.data('txHash'),
          totalAmount: this.localTxData && this.localTxData.inputsAmount || 0,
          totalAmountPrice: this.localTxData && this.localTxData.inputsAmountPrice || 0,
          totalAmountPriceTimestamp: this.localTxData && this.localTxData.inputsAmountPriceTimestamp || null,
          timestamp: this.localTxData && formatDate(this.localTxData.timestamp * 1000, 'dd.MM.yyyy HH:mm') || 0,
          data: e.target.data(),
          type: 'tx',
        }
        return
      }

      if (e.target.data('type') === 'cluster') {
        this.selectedElement = {
          value: e.target.id(),
          data: e.target.data(),
          type: 'cluster',
        }
        return
      }
      this.selectedElement = {}
    },
    redoLast() {
      const a = [...Array(this.semiAutomaticModeRedoCount).keys()]
      a.forEach(() => {
        const a = this.cytoscape.redoLastVisualization();
        const fundedNode = this.findSelectedNode(a)
        this.changeViewMode(this.viewMode)

        this.setSelectedElementData(fundedNode)
      })

      this.semiAutomaticModeCount = this.semiAutomaticModeRedoCount

      this.semiAutomaticModeRedoCount = 1
    },
    undoLast() {
      const a = [...Array(this.semiAutomaticModeCount).keys()]
      a.forEach(() => {
        const a = this.cytoscape.undoLastVisualization()
        const fundedNode = this.findSelectedNode(a)
        this.changeViewMode(this.viewMode)

        this.setSelectedElementData(fundedNode)
      })

      this.semiAutomaticModeRedoCount = this.semiAutomaticModeCount

      this.semiAutomaticModeCount = 1
    },
    findSelectedNode(data) {
      if (data) {
        return data.find(ele => ele.selected)
      }
    },
    setSemiAutomaticMode(value) {
      this.semiAutomaticMode = value
    },
    deselectNode(nodeId) {
      this.cytoscape.deselectNode(nodeId)
    },
    selectIncomers(selector) {
      this.cytoscape.selectIncomers(selector)
    },
    selectOutgoers(selector) {
      this.cytoscape.selectOutgoers(selector)
    },
    customizeNode({ description, color }) {
      this.cytoscape.cy.nodes(`#${ this.selectedNode.id }`).data({ description, color })
    },
    async addToGraph(value, reset) {
      const hashValidationResult = await validate(value, 'txHash', {name: 'Search value'})
      const addressValidationResult = await validate(value, `address:${this.coinData.family}`, { name: 'Search value' })

      if (value && (hashValidationResult.valid || addressValidationResult.valid)) {
        if (this.$can('use', 'btc')) {
          await this.getTransactionData(value, reset, false, addressValidationResult.valid)
          this.SET_STEPPED_STATE({ undo: true, redo: false })
        } else {
          if (this.$route.query.tx) {
            this.SET_SEARCH_VALUE(this.$route.query.tx)
            if (this.cytoscape) {
              this.cytoscape.setSearchValue(this.$route.query.tx)
            }
            this.searchMode = this.$route.query.type
            this.SET_VALIDATE_HASH(true)
            this.SET_VALIDATE_ADDRESS(false)
            this.loadEthData(value, false, this.directionData)
            return
          } else if (this.$route.query.address) {
            this.SET_SEARCH_VALUE(this.$route.query.address)
            if (this.cytoscape) {
              this.cytoscape.setSearchValue(this.$route.query.address)
            }
            this.searchMode = this.$route.query.type
            this.SET_VALIDATE_HASH(false)
            this.SET_VALIDATE_ADDRESS(true)
            this.loadEthData(value, false, this.directionData)
            return
          }
        }
      }
    },
    hideFromGraph() {
      this.SET_STEPPED_STATE({ undo: true, redo: false })
    },
    findRoute({ from, to }) {
      const { found } = this.cytoscape.aStar(from, to)
      if (!found) this.$toasted.global.info({ message: 'Route doesn\'t exist' })
    },
    async findIntersection(value) {
      this.isLoading = true
      const { data } = await this.getTxIntersection(value)
      if (data.length > 30) {
        if (!window.confirm(`Found ${ data.length } transactions. Visualisation can take a lot of time. Are you sure want to continue?`)) return
      }
      for (const tx of data) {
        await delay(200)
        this.cytoscape.visualizeTransactionData(tx)
      }
      this.isLoading = false
      if (!data.length) this.$toasted.global.info({ message: 'Intersection doesn\'t exist' })
    },
    importGraph(file) {
      const reader = new FileReader()
      reader.onload = this.visualizeImportedGraph
      reader.readAsText(file)
    },
    changeNodeSpacing(val) {
      this.cytoscape.rerunLayout(false, val)
    },
    async customNodesSelectBehavior(e) {
      await this.deselectNode(e.target.data('id'))
      await this.selectIncomers(`#${e.target.data('id')}`)
      await this.selectOutgoers(`#${e.target.data('id')}`)
      const a = this.cytoscape.cy.elements().filter(ele => !ele.selected() && String(ele.id()) !== String(e.target.data('cluster')))
      a.forEach(elem => {
        elem.addClass('deselected')
      })
      this.cytoscape.cy.nodes().filter(ele => ele.data('id') !== e.target.data('id')).forEach(ele => this.deselectNode(ele.data('id')))
    },
    async visualizeImportedGraph(e) {
      if (!window.confirm('Current graph will be lost. Are you sure want to continue?')) return

      try {
        const { elements, pan, zoom, searchMode, viewMode, searchValue, caseCoinData } = JSON.parse(e.target.result)

        const coinDataCase = caseCoinData
          ? caseCoinData
          : this.currencyList.find(item => item.key === searchMode)
            ? this.currencyList.find(item => item.key === searchMode)
            : this.currencyList.find(item => new RegExp(item.txRegex).test(searchValue) || new RegExp(item.addressRegex).test(searchValue))

        this.SET_COIN_TYPE(coinDataCase.key)
        this.SET_COIN_DATA(coinDataCase)

        if (new RegExp(this.coinData.txRegex).test(searchValue)) {
          this.SET_VALIDATE_HASH(true);
          this.SET_VALIDATE_ADDRESS(false);
        }

        if (new RegExp(this.coinData.addressRegex).test(searchValue)) {
          this.SET_VALIDATE_HASH(false);
          this.SET_VALIDATE_ADDRESS(true);
        }

        await this.collectPayloadForRates(elements)
        await this.getRates(this.ratesToSend)
        const elementsWithPrice = await this.addPriceToElements(elements)

        this.SET_SEARCH_VALUE(searchValue || '')
        this.searchValue = searchValue
        this.cytoscape.setSearchValue(searchValue || '')
        this.cytoscape.resetGraph()
        this.cytoscape.setViewMode(viewMode)
        this.searchMode = searchMode
        this.cytoscape.cy.json({ elements: elementsWithPrice, pan, zoom })
        this.showCyto = true
        this.selectedElement = {}
      } catch (e) {
        this.showInvalidJsonModal = true
      }
    },
    crossing() {
      this.showFindIntersectionModal = true
    },

    findClosestRatesTimestamp(contractAddress = null, timestamp) {
      if (contractAddress) {
        return this.getActualRates.find(item => item.contract === contractAddress) || null;
      }

      const closestTimestamp = this.getActualRates.reduce((closest, item) => {
        if (item.coin.toUpperCase() === this.coinType.toUpperCase()) {
          if (!closest || Math.abs(item.timestamp - timestamp) < Math.abs(closest.timestamp - timestamp)) {
            return item
          }
        }
        return closest
      }, null)

      return closestTimestamp
    },
    padTimestamp(timestamp) {
      const timestampString = timestamp.toString()
      if (timestampString.length < 13) {
          return timestampString.padEnd(13, '0')
      }
      return timestampString
    }
  },
}
</script>
<style>
.aml-detected-list {
  margin-bottom: 150px;
}
</style>
