<template>
  <div class="type-entity-table-wrapper">
    <VueSkeletonLoader
      v-if="loading"
      animation="wave"
      :color="'#bec3d8'"
      :height="20"
      :radius="'2'"
      type="rect"
    />
    <transition-group
      v-else
      appear
      name="slide"
    >
      <div
        v-for="item in dataTableResult"
        :key="item.uniqueId"
        class="type-entity-table-row-wrapper"
        :class="{
          'type-entity-table-reverse': reverse,
        }"
      >
        <div
          class="type-entity-table-row pointer"
          :class="{
            'type-entity-table-row--active': isRowActive(item),
            pointer: pointerItem,
          }"
          :style="{
            'background-color': activeBGColor(item),
          }"
          @click="$emit('type-entity-item-click', item)"
        >
          <div
            v-if="isMobile"
            class="flex fullwidth space-between"
          >
            <div class="type-entity-table-cell-name">
              <div class="type-entity-table-cell-name-text-wrapper">
                <GlScoreDot
                  class="mr-2"
                  :score="getItemScore(item)"
                />
                <div
                  v-popover:tooltip.top="`${capitalizeFirstLetter(formatLabelName(item))}`"
                  class="bold ellipsis"
                  :class="[{'link': item.entityId && isEntityLinksAvailable}]"
                  @click.stop="clickOnLabel(item)"
                >
                  {{ formatLabelName(item) }}
                </div>
              </div>
            </div>
            <div
              class="type-entity-table-cell-amount-wrapper fs-12 bold break-word ma-2 white-space-nowrap"
            >
              <div class="type-entity-table-cell-amount">
                <div>
                  {{ item.formattedAmount }}
                </div>
              </div>

              <div class="type-entity-table-cell-amount">
                <div class="fs-10 price">
                  {{
                    item.amountCur
                      ? formatByPrice(item.amountCur)
                      : item.price
                        ? formatByPrice(item.price)
                        : ''
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="type-entity-table-cell-bar-wrapper">
            <div
              class="type-entity-label-wrapper"
              :style="{
                minWidth: `${getLabelBarWidth(
                  item.share,
                  100,
                  basisPercentGraphWidth,
                )}`,
                maxWidth: `${getLabelBarWidth(
                  item.share,
                  100,
                  basisPercentGraphWidth,
                )}`,
              }"
            >
              <div
                v-if="!isMobile"
                class="type-entity-table-cell-name"
              >
                <div class="type-entity-table-cell-name-text-wrapper">
                  <GlScoreDot
                    class="mr-2"
                    :score="getItemScore(item)"
                  />
                  <div
                    v-popover:tooltip.top="`${capitalizeFirstLetter(formatLabelName(item))}`"
                    class="bold ellipsis"
                    :class="[{'link': item.entityId && isEntityLinksAvailable}]"
                    @click.stop="clickOnLabel(item)"
                  >
                    {{ formatLabelName(item) }}
                  </div>
                </div>
              </div>
              <div
                v-if="!isMobile"
                class="type-entity-table-cell-amount-wrapper fs-12 bold break-word ma-2 white-space-nowrap"
              >
                <div class="type-entity-table-cell-amount">
                  <div>
                    {{ item.formattedAmount }}
                  </div>
                </div>

                <div class="type-entity-table-cell-amount">
                  <div class="fs-10 price">
                    {{
                      item.amountCur
                        ? formatByPrice(item.amountCur)
                        : item.price
                          ? formatByPrice(item.price)
                          : ''
                    }}
                  </div>
                </div>
              </div>
            </div>

            <div
              v-popover:tooltip.top="'Indirect'"
              class="type-entity-bar-deep"
              :style="{
                'background-color': hex2rgba(
                  findColorByTypeScore(getItemScore(item)),
                  0.5,
                ),
                color: hex2rgba(findColorByTypeScore(getItemScore(item)), 0.5),
                minWidth: `${getProgressBarDeepWidth(
                  item.share,
                  item.deepDepthShare,
                  item.generalShare,
                  basisGraphWidth,
                  basisPercentGraphWidth,
                )}`,
                maxWidth: `${getProgressBarDeepWidth(
                  item.share,
                  item.deepDepthShare,
                  item.generalShare,
                  basisGraphWidth,
                  basisPercentGraphWidth,
                )}`,
              }"
            >
              <div class="type-entity-bar-share bold-600 pl-2 pr-2 main-text">
                {{
                  item.deepDepthShare > 0
                    ? formatShare(roundShare(item.deepDepthShare), '')
                    : item.generalShare > 0
                      ? ''
                      : SMALL_PERCENT_LABEL
                }}
              </div>
            </div>
            <div
              v-popover:tooltip.top="'Direct'"
              class="type-entity-bar-general"
              :style="{
                'background-color': findColorByTypeScore(getItemScore(item)),
                color: findColorByTypeScore(getItemScore(item)),
                minWidth: `${getProgressBarGeneralWidth(
                  item.share,
                  item.generalShare,
                  item.deepDepthShare,
                  basisGraphWidth,
                  basisPercentGraphWidth,
                )}`,
                maxWidth: `${getProgressBarGeneralWidth(
                  item.share,
                  item.generalShare,
                  item.deepDepthShare,
                  basisGraphWidth,
                  basisPercentGraphWidth,
                )}`,
              }"
            >
              <div class="type-entity-bar-share bold-600 pl-2 pr-2 main-text">
                {{
                  item.generalShare > 0
                    ? formatShare(roundShare(item.generalShare), '')
                    : item.deepDepthShare > 0
                      ? ''
                      : SMALL_PERCENT_LABEL
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
// Components
import GlScoreDot from '@/components/gl-score-dot'
import VueSkeletonLoader from 'skeleton-loader-vue'

// Utils
import { formatBtcAmount } from '@/utils/format-btc-amount'
import { formatShare, roundShare } from '@/utils/sourcesFormatter'
import { findColorByTypeScore, hex2rgba } from '@/utils/cytoskape-ui-rules'
import { formatByPrice } from '@/utils/format-by-price'
import { calculateScoreByRiskLevel } from '@/utils/risk-formatter'
import { goToEntity } from "@/utils/go-to-route"
import { v4 as uuidv4 } from 'uuid'

// Libs
import _ from 'lodash'

// Mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'
import { capitalizeFirstLetter } from '@/utils/text-formatter'

export default {
  components: {
    GlScoreDot,
    VueSkeletonLoader,
  },
  mixins: [deviceWidthMixin],
  props: {
    dataTable: {
      type: Array,
      default: () => [],
    },
    pointerItem: {
      type: Boolean,
      default: false,
    },
    activeTypeEntityItem: {
      type: Object,
      default: () => ({}),
    },
    maxShare: {
      type: Number,
      default: 100,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    full: {
      type: Boolean,
      default: false,
    },
    basisPercentGraphWidth: {
      type: Number,
      default: 100,
    },
    basisGraphWidth: {
      type: Number,
      default: 100,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    activePartBar: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    separationFactorPoint: {
      type: Number,
      default: 0.3,
    },
  },
  data() {
    return {
      lowPoint: 0.0001,
      SMALL_PERCENT_LABEL: '<0.01%',
    }
  },
  computed: {
    dataTableResult() {
      const processedData = (this.full
        ? this.dataTable
        : this.dataTable.slice(0, this.perPage)
      ).map(item => ({
        ...item,
        uniqueId: uuidv4(),
      }))

      return processedData
    },
    isEntityLinksAvailable() {
      return this.activePartBar.value === 'entity' || this.activePartBar.value === 'owner'
    },
  },
  methods: {
    capitalizeFirstLetter,
    goToEntity,
    formatBtcAmount,
    roundShare,
    formatShare,
    findColorByTypeScore,
    hex2rgba,
    formatByPrice,
    calculateScoreByRiskLevel,
    uuidv4,
    formatLabelName(item) {
      if (
        this.activePartBar.value === 'type' ||
        this.activePartBar.value === 'funds.type'
      ) {
        return this.formatLabel(item, 'funds.type')
      }
      if (
        this.activePartBar.value === 'entity' ||
        this.activePartBar.value === 'owner'
      ) {
        return this.formatLabel(item, 'owner')
      }
      if (this.activePartBar.value === 'riskLevel') {
        return this.formatLabel(item, 'riskLevel')
      }
    },
    getItemScore(item) {
      if (this.activePartBar.value === 'riskLevel') {
        return this.calculateScoreByRiskLevel(item.riskLevel)
      }
      return item.funds.default ? -1 : item.funds.score
    },
    formatLabel(item, target) {
      return _.get(item, target)
        ? _.get(item, target)
        : _.get(item, 'funds.name') || ''
    },
    getLabelBarWidth(share, basisWidth = 0, basisPercent = 100) {
      const s = (share / this.maxShare) * 100 * basisPercent
      return `calc(${100 - s}% - ${basisWidth}px)`
    },
    getProgressBarDeepWidth(
      share,
      itemShare,
      secondShare,
      basisWidth = 0,
      basisPercent = 100,
    ) {
      const s = (itemShare / this.maxShare) * 100 * basisPercent
      if (secondShare > 0 || (!itemShare && !secondShare)) {
        basisWidth =
          itemShare >= this.separationFactorPoint ? 0 : basisWidth / 2
      }
      if (itemShare <= 0 && secondShare > 0) basisWidth = 0

      return `calc(${s}% + ${basisWidth}px)`
    },
    getProgressBarGeneralWidth(
      share,
      itemShare,
      secondShare,
      basisWidth = 0,
      basisPercent = 100,
    ) {
      const s = (itemShare / this.maxShare) * 100 * basisPercent
      if (
        (secondShare > 0 && secondShare < this.separationFactorPoint) ||
        (!itemShare && !secondShare)
      ) {
        basisWidth = basisWidth / 2
      }
      if (itemShare <= 0 && secondShare > 0) basisWidth = 0

      return `calc(${s}% + ${basisWidth}px)`
    },
    activeBGColor(item) {
      if (
        this.activePartBar.value === 'type' &&
        this.activeTypeEntityItem &&
        this.activeTypeEntityItem.funds &&
        this.activeTypeEntityItem.funds.type
      ) {
        return item.funds.type === this.activeTypeEntityItem.funds.type
          ? this.hex2rgba(
              this.findColorByTypeScore(
                item.funds.default ? -1 : item.funds.score,
              ),
              item.funds.default ? 0.5 : 0.15,
            )
          : ''
      }
      if (
        this.activePartBar.value === 'entity' &&
        this.activeTypeEntityItem &&
        this.activeTypeEntityItem.owner
      ) {
        return item.owner === this.activeTypeEntityItem.owner
          ? this.hex2rgba(
              this.findColorByTypeScore(
                item.funds.default ? -1 : item.funds.score,
              ),
              item.funds.default ? 0.5 : 0.15,
            )
          : ''
      }
    },
    isRowActive(item) {
      if (
        this.activePartBar.value === 'type' &&
        this.activeTypeEntityItem &&
        this.activeTypeEntityItem.funds &&
        this.activeTypeEntityItem.funds.type
      ) {
        return item.funds.type === this.activeTypeEntityItem.funds.type
      }
      if (
        this.activePartBar.value === 'entity' &&
        this.activeTypeEntityItem &&
        this.activeTypeEntityItem.owner
      ) {
        return item.owner === this.activeTypeEntityItem.owner
      }
    },
    clickOnLabel(item) {
      if(!item) return 
      this.goToEntity (item.entityId)
    },
  },
}
</script>

<style>
.type-entity-table-row-wrapper {
  display: flex;
  width: 100%;
  overflow: hidden;
}
.type-entity-table-row {
  display: flex;
  width: 100%;
  min-height: 48px;
  border-bottom: 1px solid var(--pale-blue);
  flex-wrap: wrap;
}
.type-entity-table-row:hover {
  background-color: var(--pale-blue-transparent);
}

.type-entity-table-cell-name {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  flex: 1;
}

.type-entity-table-cell-name-text-wrapper {
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
}
.type-entity-table-cell-name-text-wrapper > *::first-letter {
  text-transform: capitalize;
}

.type-entity-table-cell-bar-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 2px 0;
}

.type-entity-label-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.type-entity-bar-deep,
.type-entity-bar-general {
  display: flex;
  align-items: center;
  font-size: 12px;
  min-height: 28px;
  -webkit-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  transition: all 0.5s linear;
  min-width: 50px;
}

.type-entity-table-cell-amount {
  display: flex;
  justify-content: flex-end;
}

.type-entity-table-row:hover .type-entity-bar-deep,
.type-entity-table-row:hover .type-entity-bar-general,
.type-entity-table-row--active .type-entity-bar-deep,
.type-entity-table-row--active .type-entity-bar-general {
  box-shadow: 0px -3px 0px, /* Top shadow */ 0px 3px 0px; /* Bottom shadow */
  -webkit-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.type-entity-table-reverse.type-entity-table-row-wrapper {
  direction: rtl;
}

.type-entity-table-reverse .type-entity-table-cell-name,
.type-entity-table-reverse .type-entity-table-cell-amount-wrapper {
  direction: ltr;
}

.type-entity-table-reverse .type-entity-table-cell-name-text-wrapper {
  justify-content: flex-end;
}

.type-entity-table-reverse .type-entity-bar-share {
  direction: ltr;
}

.type-entity-table-reverse .type-entity-table-cell-amount {
  justify-content: flex-start;
}

.type-entity-table-reverse .type-entity-table-row:hover .type-entity-bar {
  box-shadow: 4px 1px 0px, 4px -1px 0px, /* Left shadow */ -1px -1px 0px,
    /* Top shadow */ -1px 1px 0px; /* Bottom shadow */
}
</style>
