import { trancateString } from '@/utils/text-formatter'
import config from '@/utils/appConfig'
import appConfig from '@/utils/appConfig'
import analyticsState from '@/store/modules/analytics/state'
import { ScoringFormulaList } from '@/utils/globalConstants'

export const getEntityIds = async ({ owners = [], entityIdCache = {}, getEntityList }) => {
  const cachedResults = {};
  const uncachedOwners = [];

  // Check for cached owners first
  owners.forEach(owner => {
    if (entityIdCache[owner]) {
      cachedResults[owner] = entityIdCache[owner];
    } else {
      uncachedOwners.push(owner);
    }
  });

  // Fetch uncached owners' entity IDs
  if (uncachedOwners.length > 0) {
    try {
      const data = await getEntityList({ names: uncachedOwners });
      data?.items?.forEach(item => {
        const ownerName = item?.name?.value.toLowerCase();
        const entityId = item?.id || null;
        entityIdCache[ownerName] = entityId;
        cachedResults[ownerName] = entityId;
      });
    } catch (error) {
      console.error('Error fetching entity IDs:', error);
    }
  }

  return cachedResults;
};


export const getLabelRequest = (hash) => {
  const tags = {
    'MONITORING_ADD': 'Monitoring',
    'SANKEY_REPORT_CREATE': 'Counterparty',
    'REPORT_GET': 'Report'
  }

  return tags[hash] || '-'
}

export const calcSourcesRiskPercent = (sources) =>
  sources?.reduce((acc, curr) => {
      if (curr.funds.score >= Number(config.VUE_APP_RISK_SCORE_TRESHOLD)) {
          acc += curr.share
      }

      return acc
  }, 0)

export const formatFunds = (fund, withPercent = true) => {
    const formatted = Math.ceil(fund)

    if (withPercent) {
        return formatted === '0.00' ? '< 0.01%' : formatted + '%'
    } else {
        return formatted === '0.00' ? '< 0.01' : formatted
    }
}

export const formatterAmountValue = (amount, decimals, symbol, coinData = analyticsState.coinData, fixedVal = 8) => {
    const resSymbol = symbol || analyticsState?.coinData?.label;

    if (!amount) {
        return `0 ${resSymbol}`;
    }

    const fAmount = amount ? Number(amount) / Math.pow(10, decimals ?? (coinData?.decimals ?? 1)) : 0;

    if (fAmount > 999) {
      fixedVal = 4
    }

    const formattedAmount = Math.abs(fAmount) < 0.00000001 ? '< 0.00000001' : fAmount.toFixed(fixedVal);

    return `${formattedAmount} ${resSymbol}`;
};


export const getOwnerByType = (data) => {
    if (data.funds && data.funds.name && data.funds.name.toLowerCase() === 'unidentified service / exchange') {
        return data.cluster || data.owner
    }

    if (data.funds && data.funds.name && data.funds.name.toLowerCase() === 'small transactions') {
        return 'Multiple'
    }

    if (data.funds && data.funds.name && data.funds.name.toLowerCase() === 'unknown single wallet service') {
        return data.address
            ? {
                owner: trancateString(data.address, 8),
                value: data.address,
                isLink: true
            }
            : 'Multiple'
    }

    if (data.funds && data.funds.name && data.funds.name.toLowerCase() === 'unknown wallet / otc / service') {
        return data.address
            ? {
                owner: trancateString(data.address, 8),
                value: data.address,
                isLink: true
            }
            : data.owner
    }

    if (data.funds && data.funds.name && data.funds.name.toLowerCase() === 'maximum depth reached' || data.funds && data.funds.name && data.funds.name.toLowerCase() === 'small transactions') {
        return 'Multiple'
    }

    return data.owner || '--'
}

export const checkMultiple = (val) => {
    return val === null || val === 'Multiple'
}

export const ownerLabelFormatter = (addressData) => {
    if (!addressData) return 'Not identified'
    if (addressData.owner || (addressData.clusterData && addressData.clusterData.owner)) {
        if (addressData.owner === addressData.clusterData && addressData.clusterData.owner) {
            return addressData.owner
        }

        if (addressData.owner && addressData.clusterData && addressData.clusterData.owner) {
            return `${addressData.owner}, ${addressData.clusterData.owner}`
        }

        if (addressData.clusterData && addressData.clusterData.owner) {
            return addressData.clusterData.owner
        }

        if (addressData.owner) {
            return addressData.owner
        }
    }

    return 'Not identified'
}

export const isOwnerExist = (addressData) => {
    if (!addressData) return false
    if (addressData.owner ||
        addressData.description ||
        addressData.tags && addressData.tags.length ||
        addressData.type) return true
    return false
}

export const isClusterExist = (addressData) => {
    if (!addressData && !addressData.clusterData) return false
    if (addressData.clusterData &&
        addressData.clusterData?.description ||
        addressData.clusterData?.owner ||
        addressData.clusterData?.tags && addressData.clusterData?.tags.length ||
        addressData.clusterData?.type) return true
    return false
}

export const filterSourceResultByEntityType = (sources, activeItem, activePartBar) => {
    return (
        sources &&
        sources.filter(row => {
          if (activePartBar === 'type') {
            if (activeItem && activeItem.funds && activeItem.funds.type) {
              return row.funds.name === activeItem.funds.type
            } else return row
          }
          if (activePartBar === 'entity') {
            if (activeItem && activeItem.owner) {
              return row.owner === activeItem.owner
            } else return row
          }
          return row
        })
      )
}

function ceil(x, unit, epsilon) {
  return !unit ? x : Math.ceil((x  - epsilon) * unit) / unit
}

export const calcTotalFunds = (inputs = [], outputs = []) => {
  const sources = [...outputs, ...inputs]
  const inSum = inputs.reduce((acc , it) => acc + it.amount, 0)
  const outSum = outputs.reduce((acc , it) => acc + it.amount, 0)
  const totalAmount = inSum + outSum;

  let totalFundsDefault = 0
  let totalFundsSensitive = 0

  // Default|Sensitive

  for (let s=0; s<sources.length; s++) {
    const {score} = sources[s].funds
      , {amount} = sources[s]
      , flowScore = amount * score / totalAmount
    totalFundsDefault += flowScore
    totalFundsSensitive += flowScore * score
  }
  let scorePrecision = 1
  let scoreEpsilon = 1e-12

  // Series
  const allScores = sources.map(item => item.funds.score);
  const scores = Array.from(new Set(allScores)).sort((a, b) => b - a)
  const b = scores[0] > appConfig.VUE_APP_RISK_MEDIUM_THRESHOLD ? appConfig.VUE_APP_RISK_HIGH_THRESHOLD
    : scores[0] > appConfig.VUE_APP_RISK_LOW_THRESHOLD ? appConfig.VUE_APP_RISK_MEDIUM_THRESHOLD
      : appConfig.VUE_APP_RISK_LOW_THRESHOLD
    , ext = b - scores[0]

  let sum = scores[0]

  for (let s = 1; s < scores.length; s++) {
    const cur = scores[s]

    sum += ext / (1 + b - cur)
  }

  const defaultTotalFunds = ceil(totalFundsDefault, scorePrecision, scoreEpsilon)
  const sensitiveTotalFunds = ceil(Math.sqrt(totalFundsSensitive), scorePrecision, scoreEpsilon)
  const seriesTotalFunds = Math.min(Math.ceil(sum), 100)

  const SCORING_FORMULA = localStorage.getItem('scoringFormula') || ScoringFormulaList[0].key

  let result;

  switch (SCORING_FORMULA) {
    case 'Series':
      result = seriesTotalFunds;
      break;
    case 'Sensitive':
      result = sensitiveTotalFunds;
      break;
    case 'Default':
    default:
      result = defaultTotalFunds;
      break;
  }

  return result;
};
